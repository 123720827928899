<div *ngFor="let conditionGroup of ruleGroup; index as groupIdx" class="builder-rule-group">
  <div class="include-group" *ngIf="nestLevel === 0">
      <div class="condition-group-header">
        <div [class.condition-true]="queryFromTextAI" class="headerAndToggle">
          <div class="iconHeader">
            <lib-type-icon [type]="conditionGroup[0]?.typeName" [scale]=1.5> </lib-type-icon>
            <h4>I want to {{ (conditionGroup[0]?.typeName).toLowerCase() }}...</h4>
          </div>
        </div>
      </div>
      <div class="dropPlaceholder" *ngFor="let expressionGroup of conditionGroup; index as ix" [class.hidden]="
          ix !== 0 && hideExtraGroup(expressionGroup, conditionGroup)
        ">
        <ng-container *ngLet="{
            subExpressionGroup: expressionGroup.group,
            groupAttrs: getAttributesInGroup(expressionGroup.group),
            aggregateAttrs : getAggregateAttributesInGroup(expressionGroup.group)
          } as opts">
          <div class="multi-splitter betweenSections" *ngIf="ix !== 0 && nextGroup(conditionGroup, ix)">
            <coreui-menu menuAlignment="left" [buttonLabel]="getOperatorLabel(expressionGroup.logicalOperator).toLowerCase()"
              menuButtonStyle="link" class="boolean-menu" verticalPosition="bottom" iconAlignment="right"
              *ngIf="ix !== 0">
              <coreui-menu-section>
                <button type="button" coreUIMenuItem [class.boolean-selected]="
                    isBooleanSelected(expressionGroup, item)
                  " (click)="updateGroupLogicalOperator(item, expressionGroup)" *ngFor="let item of booleanOperators">
                  {{ getOperatorLabel(item).toLowerCase() }}
                </button>
              </coreui-menu-section>
            </coreui-menu>
          </div>
          <div class="drop-list nest-level-{{ nestLevel }} expression-group-idx-{{
              ix
            }}" coreUIDropList (dropped)="onDropped($event, groupIdx, true)" (entered)="isDropAllowed($event)"
            (exited)="enableDrop()" [data]="opts.subExpressionGroup" [connectedTo]="dragDropService.dropLists$ | async"
            [class.empty-dropList]="!(opts.subExpressionGroup &&  opts.subExpressionGroup.length)"  [class.drop-disabled]="dropDisabled$ | async" [class.definition-is-nested]="definitionIsNested$ | async">
              <ng-container *ngFor="let item of opts.subExpressionGroup; index as idx">
              <div coreUIDrag class="drag-element" (started)="toggleDrag()" (ended)="toggleDrag()"
                *ngIf="item['expression'] || item.aggregationConditions?.length > 0">
                <ng-template coreUIDragPreview [matchSize]="true" *ngIf="item?.['expression']">
                  <lib-builder-attribute [dedupeType]="dedupeType" [fromAggregationGroup]="false" [attributeIndex]="idx"
                    [attribute]="item" [group]="opts.subExpressionGroup" [isPreview]="true"
                    [attributeEnumValues]="attributeEnumValues[item['expression'].firstOperand?.cabId]"></lib-builder-attribute>
                </ng-template>
                <lib-builder-attribute [dedupeType]="dedupeType" [fromAggregationGroup]="false" [attributeIndex]="idx"
                  [attribute]="item" [group]="opts.subExpressionGroup"
                  (attributeDelete)="clearDeletedAttributes(ruleGroup, groupIdx, ix)"
                  [attributeEnumValues]="attributeEnumValues[item['expression'].firstOperand?.cabId]"
                  *ngIf="item['expression']"></lib-builder-attribute>
                <lib-builder-aggregate-group [dedupeType]="dedupeType" [aggregateAttributeIndex]="idx"
                  [groupArray]="opts.subExpressionGroup" [attribute]="item"
                  *ngIf="item.aggregationConditions?.length > 0"
                  [group]="opts.subExpressionGroup"></lib-builder-aggregate-group>
                <div class="splitter"
                  *ngIf="
                    opts.subExpressionGroup.length !== idx + 1 &&
                    (isDragging$ | async) === false && (opts.subExpressionGroup[idx+1]?.aggregationConditions?.length > 0 || opts.subExpressionGroup[idx+1]?.['expression'] )">
                  <coreui-menu menuAlignment="left" [buttonLabel]="
                      getOperatorLabel(nextGroup(opts.subExpressionGroup, idx)?.logicalOperator).toLowerCase()
                    " menuButtonStyle="link" class="boolean-menu" verticalPosition="bottom" iconAlignment="right">
                    <coreui-menu-section>
                      <button type="button" coreUIMenuItem [class.boolean-selected]="
                          isBooleanSelected(
                            nextGroup(opts.subExpressionGroup, idx),
                            operator
                          )
                        " (click)="
                          updateGroupLogicalOperator(
                            operator,
                            nextGroup(opts.subExpressionGroup, idx)
                          )
                        " *ngFor="let operator of booleanOperators">
                        {{ getOperatorLabel(operator).toLowerCase() }}
                      </button>
                    </coreui-menu-section>
                  </coreui-menu>
                </div>
              </div>
            </ng-container>
            <div *ngIf="!isBuilderView">
              <div *ngIf="!(opts.subExpressionGroup &&  opts.subExpressionGroup.length)" class="empty-list text-style-muted-1">
                <div *ngIf="queryFromTextAI else defaultEmptyList" class="generate-ai">
                  <span>Drag and drop an item from the left panel or</span> <button *ngIf="queryFromTextAI" coreUIButton="micro-secondary" class="generateAIButton" (click)="showGenerateAIPrompt(conditionGroup[0]?.typeName)"><i coreUIIcon="wand" scale="1"></i>
                    Generate Criteria</button><span>using the power of AI</span>
                </div>
                <ng-template #defaultEmptyList>
                  Drag and drop an item from the left
                </ng-template>
              </div>  
            </div>
          </div>
        <ng-container *ngIf="opts.subExpressionGroup && opts.subExpressionGroup[opts.subExpressionGroup.length - 2]">
          <ng-container  *ngLet="opts.subExpressionGroup[opts.subExpressionGroup.length - 2] as item">
            <ng-container *ngIf="item">
              <div class="criteriaAccuracyMessage">
                <div id="flash-info-notification---import" class="text-style-muted-1" *ngIf="(item.genAIRequestId || expressionGroup.genAIRequestId) && (item.feedbackStatus !== 'submitted')">
                  <span>Is the audience criteria accurate?</span>
                  <span>
                  <i class="thumbs-up-down" coreUIIcon="thumbsUp" scale="1.5" (click)="submitFeedback(1, item.genAIRequestId || expressionGroup.genAIRequestId)"></i>
                  <i class="thumbs-up-down" coreUIIcon="thumbsDown" scale="1.5" (click)="submitFeedback(-1, item.genAIRequestId || expressionGroup.genAIRequestId)"></i>
                  </span>
                </div>
              </div>
              <div class="feedback" *ngIf="item.feedbackStatus === 'submitted' || expressionGroup.feedbackStatus === 'submitted'">
                <coreui-notification notificationStyle="success" [autoShow]="true" [isPersistent]="true">
                  Thank you! Your feedback will help improve future results.
                </coreui-notification>
              </div>
            </ng-container>
          </ng-container>
          </ng-container>
          <lib-builder-group
            *ngIf="!expressionGroup['displayName'] && expressionGroup.aggregationConditions?.length === 0 && (opts.groupAttrs?.length > 0 || opts.aggregateAttrs?.length > 0)"
            [nestLevel]="nestLevel + 1" [ruleGroup]="[findGroupItem(opts.subExpressionGroup)]" [parent]="conditionGroup"
            [parentExpressionIdx]="ix" [dedupeType]="dedupeType">
          </lib-builder-group>
        </ng-container>
      </div>
  </div>

  <div *ngIf="nestLevel > 0" class="nested-sub-group">
    <ng-container *ngLet="{
        subConditionGroup: conditionGroup['group'],
        groupAttrs: getAttributesInGroup(conditionGroup['group'])
      } as opts">
      <div class="multi-splitter" *ngIf="opts.subConditionGroup.length > 1">
        <coreui-menu menuAlignment="left" [buttonLabel]="getOperatorLabel(conditionGroup['logicalOperator']).toLowerCase()"
          menuButtonStyle="link" class="boolean-menu" verticalPosition="bottom" iconAlignment="right">
          <coreui-menu-section>
            <button type="button" coreUIMenuItem [class.boolean-selected]="isBooleanSelected(conditionGroup, item)"
              (click)="updateGroupLogicalOperator(item, conditionGroup)" *ngFor="let item of booleanOperators">
              {{ getOperatorLabel(item).toLowerCase() }}
            </button>
          </coreui-menu-section>
        </coreui-menu>
      </div>
      <div class="nested-drop-list nest-level-{{
          nestLevel
        }} expression-group-idx-{{ parentExpressionIdx }}" coreUIDropList
        (dropped)="onDropped($event, groupIdx, false)" (entered)="isDropAllowed($event)" (exited)="enableDrop()"
        [data]="opts.subConditionGroup" [connectedTo]="dragDropService.dropLists$ | async"
        [class.drop-disabled]="dropDisabled$ | async" [class.definition-is-nested]="definitionIsNested$ | async">
        <ng-container *ngFor="let subGroupItem of opts.subConditionGroup; index as subIdx">
          <div coreUIDrag class="drag-element"
            *ngIf="subGroupItem.expression || subGroupItem.aggregationConditions?.length > 0">
            <ng-container *ngLet="nextGroup(opts.subConditionGroup, subIdx, true) as nextSubGroup">
              <ng-template coreUIDragPreview [matchSize]="true">
                <lib-builder-attribute [attribute]="subGroupItem" [group]="opts.subConditionGroup" [isPreview]="true"
                  [attributeIndex]="subIdx" [dedupeType]="dedupeType"
                  [attributeEnumValues]="attributeEnumValues[subGroupItem.expression?.firstOperand?.cabId]"></lib-builder-attribute>
              </ng-template>
              <lib-builder-attribute [attribute]="subGroupItem" [pickerAttribute]="subGroupItem?.pickerAttribute"
                [group]="opts.subConditionGroup" [attributeIndex]="subIdx" *ngIf="subGroupItem.expression"
                [dedupeType]="dedupeType"
                [attributeEnumValues]="attributeEnumValues[subGroupItem.expression?.firstOperand?.cabId]">
              </lib-builder-attribute>
              <lib-builder-aggregate-group [dedupeType]="dedupeType" [aggregateAttributeIndex]="subIdx"
                [groupArray]="opts.subConditionGroup" [attribute]="subGroupItem"
                *ngIf="subGroupItem.aggregationConditions?.length > 0"></lib-builder-aggregate-group>
              <div class="splitter"
                *ngIf="opts.subConditionGroup.length !== subIdx + 1 && (opts.subConditionGroup[subIdx+1]?.aggregationConditions?.length > 0 || opts.subConditionGroup[subIdx+1]?.expression)">
                <coreui-menu menuAlignment="left" [buttonLabel]="getOperatorLabel(nextSubGroup?.logicalOperator).toLowerCase()"
                  menuButtonStyle="link" class="boolean-menu" verticalPosition="bottom" iconAlignment="right">
                  <coreui-menu-section>
                    <button type="button" coreUIMenuItem [class.boolean-selected]="
                      isBooleanSelected(nextSubGroup, operator)
                    " (click)="updateGroupLogicalOperator(operator, nextSubGroup)"
                      *ngFor="let operator of booleanOperators">
                      {{ getOperatorLabel(operator).toLowerCase() }}
                    </button>
                  </coreui-menu-section>
                </coreui-menu>
              </div>
            </ng-container>
          </div>
        </ng-container>
          <div class="subgroup-container" *ngIf="!isBuilderView">
            <div class="subgroup-drop-space empty-list text-style-muted-1"  *ngIf="opts.subConditionGroup.length <= 0">
              Drag and drop an item from the left
             </div>
          </div>
      </div>
      <lib-builder-group *ngIf="findGroupItem(opts.subConditionGroup)"
        [ruleGroup]="[findGroupItem(opts.subConditionGroup)]" [parent]="ruleGroup" [nestLevel]="nestLevel + 1"
        [parentExpressionIdx]="parentExpressionIdx" [dedupeType]="dedupeType">
      </lib-builder-group>
    </ng-container>
  </div>
</div>

<lib-generate-criteria #generateAIPrompt></lib-generate-criteria>
