<lib-profile-section *ngIf="audienceForm" [activeDedupeType]="audienceForm?.get('dedupeType').value" [isDefinitionFlow]="true" [isEdit]="isEdit"
  [audienceForm]="audienceForm" (handleInvalidDefinitionError)="triggerHandleInvalidDefinitionError()"
  (submitUpdateCount)="triggerSubmitUpdateCount($event)"></lib-profile-section>

<section class="component-wrapper">
  <div class="details-toggle-dropdown">
    <h2>Criteria Builder</h2>
    <lib-details-toggle-dropdown
      [caseInsensitiveControl]="audienceForm && audienceForm.controls['audienceQueryCaseInsensitive']"
      [caseInsensitiveDisabled]="false"
    ></lib-details-toggle-dropdown>
  </div>

  <div class="builder-form-container">
        <div class="lib-builder-group">
          <lib-builder-group [ruleGroup]="builderService.rules" [dedupeType]="audienceForm.get('dedupeType')"
            parentExpressionIdx=0></lib-builder-group>
        </div>
  </div>
</section>
