<form [formGroup]="audienceAIFormGroup" (ngSubmit)="submitQuery()">
  <div *ngIf="chatConversations.chat_history.length <= 2 && chatConversations.chat_history.length > 0">
    <lib-audience-ai-welcome [AudienceAITag]="AudienceAITag" [chatInput]="chatInput" [AssistantIcon]="AssistantIcon"
      [typingMessage]="typingMessage" [inputContext]="getInputContext()"
      [chatContent]="this.chatConversations?.chat_history[1]?.content">
    </lib-audience-ai-welcome>
  </div>
  <div class="container" *ngIf="chatConversations.chat_history.length > 2">
    <!-- Left Container -->
    <div class="left-section">
      <ng-container *ngTemplateOutlet="AudienceAITag"> </ng-container>
      <div class="chat-section">
        <div #chatMessages class="chat-messages" *ngIf="chatConversations.chat_history" (scroll)="onScroll(chatContainer)">
          <div *ngFor="let chat of chatConversations.chat_history; let i = index">
            <div *ngIf="i > 0 && dateGrouping(chat, i)" class="inline-container">
              <span coreUIDivider class="divider-before"></span>
              <span class="divider-text">{{chat.additional_kwargs?.created_at | date:'dd - MMM - y'}}</span>
              <span coreUIDivider class="divider-after"></span>
            </div>
            <div *ngIf="chat.role === 'human' && i!==0" class="user-chat">
              <div class="user-text text-style-3" [innerHTML]="chat.content | markdownToHtml"></div>
            </div>
            <div *ngIf="chat.role === 'ai'" class="assistant-chat">
              <div class="assistant-icon-container"> <ng-container *ngTemplateOutlet="AssistantIcon"></ng-container> </div>
              <div class="assistant-text error-message-container" *ngIf="displayErrorMessage(chat)">
                <i class="error-icon" coreUIIcon="errorShield" scale="1.5"></i>
                <div>
                  <span class="text-style-3">{{chat.content}}</span>
                  <button class="regenerate" type="button" coreUIButton="text-primary" withIcon="left" (click)="onRegenerate()"><i
                      coreUIIcon="refresh"></i> Regenerate
                  </button>
                </div>
              </div>
              <div class="chat-message-container" *ngIf="!displayErrorMessage(chat)">
                <div class="assistant-text text-style-3">
                  <div class="nonsqlContent" [innerHTML]="chat.content | markdownToHtml"></div>
                  <coreui-card [text]="sqlContent" class="sqlContent" *ngIf="chat.additional_kwargs?.['sqlClause']">
                    <ng-template #sqlContent>
                      <coreui-standalone-accordion label="View definition criteria">
                        <div [innerHTML]="addSQLTags(chat.additional_kwargs['sqlClause']) | markdownToHtml"></div>
                      </coreui-standalone-accordion>
                    </ng-template>
                  </coreui-card>
                  <ng-container *ngTemplateOutlet="previewTable; context: { $implicit: chat }">
                  </ng-container>
                </div>
                <div *ngIf="isFeedbackMenuShown(chat.genAIRequestIds)" class="feedback-container">
                  <div class="feedback-overview">
                    <span class="text-style-muted-1">Is the audience criteria accurate?</span>
                    <div class="feedback-icons">
                      <button [ngClass]="{ 'selected-feedback-icon': isFeedbackSelected(i, 1) }" type="button" coreUIButton="icon-large"
                        (click)="selectFeedback(1, i)"><i coreUIIcon="thumbsUp"></i></button>
                      <button [ngClass]="{ 'selected-feedback-icon': isFeedbackSelected(i, -1) }" type="button" coreUIButton="icon-large"
                        (click)="selectFeedback(-1, i)"><i coreUIIcon="thumbsDown"></i></button>
                    </div>
                  </div>
                  <coreui-card *ngIf="isCommentAreaShown(i)" class="comment-section">
                    <div class="comment-header">
                      <div>
                        <span class="text-style-2">Tell us more </span>
                        <span class="text-style-muted-4">(Optional)</span>
                      </div>
                      <button type="button" coreUIButton="icon-large" (click)="close(i)"><i coreUIIcon="close"></i></button>
                    </div>
                    <coreui-form-field>
                      <textarea class="comment-text-area" coreUIInput formControlName="commentMessage"></textarea>
                      <div>
                        <button type="submit" coreUIButton="primary" (click)="submitFeedback(i)">Submit</button>
                      </div>
                    </coreui-form-field>
                  </coreui-card>
                  <coreui-notification [notificationStyle]="notificationStyle" [isPersistent]="true" [autoShow]="true"
                    *ngIf="chat.showNotification"> {{ notificationMessage }} </coreui-notification>
                </div>
                <div class="assistant-options" *ngIf="chatConversations.chat_history.length === i+1">
                  <button class="option-buttons" *ngFor="let option of chat.options" type="button"
                    coreUIButton="micro-secondary" (click)="selectOption(option)">{{option}}</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isQueryRunning && chatConversations.chat_history.length > 2">
            <div class="assistant-chat">
              <ng-container *ngTemplateOutlet="AssistantIcon"></ng-container>
              <ng-container *ngTemplateOutlet="typingMessage"></ng-container>
              <div class="showCancel" *ngIf="showCancel">
                <button type="button" coreUIButton="text-primary" (click)="cancelJob()"> Cancel {{ jobType }} </button>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll" *ngIf="!isScrollAtBottom">
          <button class="scroll-button" coreUIButton="icon" (click)="scrollToBottom(chatContainer)">
            <i scale="1.5" coreUIIcon="arrowDown"></i>
          </button>
        </div>
        <ng-container *ngTemplateOutlet="chatInput; context: { $implicit: getInputContext() }"></ng-container>
      </div>
    </div>
    <!-- Right Container -->
    <div class="right-section">
      <div class="preview-header">
        <i coreUIIcon="eyeOpen" class="icon-eye"></i>
        <h3 class="definition-text">Audience Definition</h3>
      </div>
      <ng-container *ngTemplateOutlet="previewTemplate">
      </ng-container>
      <button class="icon-tertiary-button" coreUIButton="secondary" withIcon="left" (click)="openRestartConfirmationPopup()">
        <i coreUIIcon="sync"></i>Restart
      </button>
    </div>
  </div>
</form>
<lib-confirmation-popup #restartConfirmationPopup [title]="confirmationTitle" [confirmationMessage]="customMessage"
  [confirmButtonText]="confirmText" [cancelButtonText]="cancelText" (confirmEvent)="handleRestartConfirm()"
  (cancelEvent)="handleCancel()">
</lib-confirmation-popup>

<div>
  <coreui-notification notificationStyle="error" #toastError>
    {{ errorMessage }}</coreui-notification>
</div>

<ng-template #previewTable let-chat>
  <div class="table-container"
    *ngIf="chat.additional_kwargs?.['sampleData']?.length > 0 && chat.additional_kwargs?.['previewId']">
    <coreui-table>
      <thead>
        <tr coreUITableHeaderRow>
          <th *ngFor="let header of chat.additional_kwargs?.['tableHeaders']">{{header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of chat.additional_kwargs?.['sampleData']" coreUITableRow>
          <td *ngFor="let header of chat.additional_kwargs?.['tableHeaders']">{{row[header]}}</td>
        </tr>
      </tbody>
    </coreui-table>
  </div>
</ng-template>

<ng-template #AudienceAITag>
  <div class="header-container">
    <button type="button" coreUIButton="icon-large" (click)="handleBackAction()">
      <i coreUIIcon="arrowLeft"></i>
    </button>
    <h2 class="header-title">Audience AI</h2>
    <div class="beta-tag text-style-4">BETA</div>
  </div>
</ng-template>
<lib-confirmation-popup  #backConfirmationPopup *ngIf="showSaveWarning"
  (confirmEvent)="handleBackConfirm()"
  (cancelEvent)="handleCancel()" [confirmButtonType]="'secondary'">
</lib-confirmation-popup>

<ng-template #AssistantIcon>
  <div class="assistant-icon"><i coreUIIcon="wand"></i></div>
</ng-template>

<ng-template #typingMessage>
  <div class="typing-message">
    <span class="typing-message-circle"></span>
    <span class="typing-message-circle"></span>
    <span class="typing-message-circle"></span>
  </div>
</ng-template>

<ng-template #chatInput let-context>
  <form [formGroup]="audienceAIFormGroup">
    <div class="chat-input-wrapper">
      <textarea rows="1" class="chat-input-area" coreUIInput placeholder="Type your response here..."
        formControlName="chatInput" (input)="onChatInputChange($event)" (keydown)="onKeyDown($event)">
      </textarea>
      <button class="chat-input-button" *ngIf="!isQueryRunning" type="submit" coreUIButton="icon"
        [disabled]="isUserInputValid">
        <i scale="1.7" coreUIIcon="arrowUp" (click)="submitQuery()"></i>
      </button>
    </div>
    <div class="input-message">
      <span>Audience AI is in beta stage and can make mistakes. Check important info.</span>
    </div>
  </form>
</ng-template>

<ng-template #previewTemplate>
  <div #previewContent class="preview-content">
    <div class="no-preview-content" *ngIf="!audienceAIFormGroupPreviewDataHasSomeValues">
      <ng-container *ngTemplateOutlet="currentTheme ? fullAudienceDarkTheme : fullAudienceLightTheme"></ng-container>
      <div class="helper-text"><span>As you start building your audience definition, you'll see it take shape in this
          panel.</span></div>
    </div>
    <coreui-form-field label="Name" *ngIf="audienceAIFormGroup.get('previewData').value.audience_name">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.audience_name }}
      </div>
    </coreui-form-field>

    <coreui-form-field label="Description" *ngIf="audienceAIFormGroup.get('previewData').value.description">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.description }}
      </div>
    </coreui-form-field>

    <coreui-form-field label="Channel type" *ngIf="audienceAIFormGroup.get('previewData').value.channel">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.channel }}
      </div>
    </coreui-form-field>

    <coreui-form-field label="Definition consists of" *ngIf="audienceAIFormGroup.get('previewData').value.audience_type">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.audience_type }}
      </div>
    </coreui-form-field>

    <coreui-form-field label="Dedupe the audience by" *ngIf="audienceAIFormGroup.get('previewData').value.dedupe">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.dedupe }}
      </div>
    </coreui-form-field>

    <coreui-form-field label="Definition Prompt" *ngIf="audienceAIFormGroup.get('previewData').value.input_query">
      <div class="preview-text-area">
        {{ audienceAIFormGroup.get('previewData').value.input_query }}
      </div>
    </coreui-form-field>

    <div *ngIf="audienceAIFormGroup.get('previewData').value.audience_query">
      <coreui-form-field class="preview-query" label="Definition Criteria">
        <div class="preview-text-area"
          [innerHTML]="addSQLTags(audienceAIFormGroup.get('previewData').value.audience_query) | markdownToHtml">
        </div>
      </coreui-form-field>
      <div class="expand-copy-buttons">
        <button coreUIButton="text-primary" (click)="expandQueryModal.show()" withIcon="left">
          <i coreUIIcon="expandWindow"></i> Expand </button>
        <button coreUIButton="text-primary" (click)="copyToClipboard()" withIcon="left">
          <i coreUIIcon="copy"></i> Copy to clipboard </button>
      </div>
    </div>

    <coreui-form-field label="Count" *ngIf="count >= 0">
      <div class="preview-text-area"> {{ count }} </div>
    </coreui-form-field>
  </div>
</ng-template>

<coreui-modal panelCssClass="expand-query-panel" modalWidth="80" modalHeight="80" [autoFocus]="false" #expandQueryModal>
  <ng-template #titleTemplate>Definition Criteria</ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="expandQueryModal.hide()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="expand-query"
      [innerHTML]="addSQLTags(audienceAIFormGroup.get('previewData').value.audience_query) | markdownToHtml">
    </div>
    <div class="copy">
      <button type="button" coreUIButton="text-primary" (click)="copyToClipboard()" withIcon="left">
        <i coreUIIcon="copy"></i> Copy to clipboard </button>
    </div>
  </ng-template>
</coreui-modal>

<ng-template #fullAudienceLightTheme>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6071_3440)">
      <path
        d="M98.145 19.3951C107.317 23.8651 112.23 33.2401 115.837 42.6751C119.422 52.1101 121.695 61.5976 118.41 69.9076C115.147 78.2101 106.297 85.3501 95.9925 91.9651C85.6875 98.6026 73.92 104.753 62.43 104.475C50.91 104.198 39.69 97.5076 29.9775 90.7201C20.265 83.9551 12.06 77.0926 6.52498 68.5726C0.967484 60.0451 -1.92752 49.8676 1.43248 40.9501C4.81498 32.0326 14.46 24.3826 25.6275 20.3926C36.7725 16.4251 49.4775 16.1101 62.5275 15.7426C75.5775 15.3526 88.9725 14.9251 98.145 19.3951Z"
        fill="url(#paint0_linear_6071_3440)" />
      <path
        d="M33.7725 30.5325C33.8325 30.3075 33.945 30.0975 34.1025 29.9175C33.96 30.0975 33.84 30.3075 33.7725 30.5325Z"
        fill="#2B99FF" />
      <path
        d="M60.975 45.4725C64.7361 45.4725 67.785 42.4236 67.785 38.6625C67.785 34.9015 64.7361 31.8525 60.975 31.8525C57.214 31.8525 54.165 34.9015 54.165 38.6625C54.165 42.4236 57.214 45.4725 60.975 45.4725Z"
        fill="#F045CB" />
      <path
        d="M54.225 64.9125V64.845C52.83 65.1975 51.42 64.08 50.8875 62.745C50.355 61.41 50.4525 59.925 50.46 58.4925C50.46 56.7075 50.3025 54.9075 50.5425 53.1375C50.7825 51.3675 51.48 49.575 52.8675 48.4575C54.945 46.7925 57.885 47.0475 60.5475 47.0475C62.25 47.0475 63.96 46.92 65.64 47.175C67.32 47.43 69.015 48.1275 70.0575 49.4775C70.8825 50.5425 71.22 51.9075 71.4375 53.2425C71.91 56.1 71.9475 59.0325 71.55 61.8975C71.4675 62.505 71.355 63.1275 71.01 63.63C70.3425 64.5975 69.045 64.8375 67.8825 65.0025C67.545 69.7575 67.2 74.505 66.8625 79.26C66.7875 80.2725 66.6675 81.39 65.91 82.0575C65.43 82.4775 64.785 82.6425 64.1625 82.785C62.64 83.1225 61.08 83.385 59.5275 83.2425C58.2225 83.1225 56.325 82.65 55.485 81.525C54.645 80.4 54.795 78.06 54.735 76.6875C54.57 72.765 54.3975 68.8425 54.2325 64.9275L54.225 64.9125Z"
        fill="url(#paint1_linear_6071_3440)" />
      <path
        d="M40.11 48.4649C43.0509 48.4649 45.435 46.0809 45.435 43.1399C45.435 40.199 43.0509 37.8149 40.11 37.8149C37.1691 37.8149 34.785 40.199 34.785 43.1399C34.785 46.0809 37.1691 48.4649 40.11 48.4649Z"
        fill="#F045CB" />
      <path
        d="M34.8375 63.6675V63.615C33.75 63.8925 32.6475 63.015 32.2275 61.9725C31.8075 60.93 31.89 59.7675 31.89 58.6425C31.89 57.2475 31.77 55.8375 31.95 54.4575C32.13 53.0775 32.6775 51.6675 33.7725 50.7975C35.3925 49.5 37.695 49.695 39.78 49.695C41.1075 49.695 42.45 49.5975 43.7625 49.7925C45.075 49.9875 46.4025 50.535 47.2125 51.5925C47.8575 52.425 48.12 53.49 48.2925 54.5325C48.66 56.7675 48.69 59.055 48.3825 61.305C48.315 61.7775 48.2325 62.265 47.9625 62.6625C47.445 63.42 46.425 63.6075 45.5175 63.735C45.2475 67.4475 44.985 71.1675 44.715 74.88C44.655 75.6675 44.565 76.545 43.9725 77.07C43.5975 77.4 43.095 77.5275 42.6075 77.64C41.415 77.9025 40.1925 78.105 38.985 77.9925C37.965 77.9025 36.48 77.5275 35.8275 76.65C35.175 75.7725 35.2875 73.9425 35.2425 72.8625C35.115 69.795 34.98 66.735 34.8525 63.6675H34.8375Z"
        fill="url(#paint2_linear_6071_3440)" />
      <path
        d="M81.9375 48.4649C84.8785 48.4649 87.2625 46.0809 87.2625 43.1399C87.2625 40.199 84.8785 37.8149 81.9375 37.8149C78.9966 37.8149 76.6125 40.199 76.6125 43.1399C76.6125 46.0809 78.9966 48.4649 81.9375 48.4649Z"
        fill="#F045CB" />
      <path
        d="M76.6575 63.6675V63.615C75.57 63.8925 74.4675 63.015 74.0475 61.9725C73.6275 60.93 73.71 59.7675 73.71 58.6425C73.71 57.2475 73.59 55.8375 73.77 54.4575C73.95 53.0775 74.4975 51.6675 75.5925 50.7975C77.2125 49.5 79.515 49.695 81.6 49.695C82.9275 49.695 84.27 49.5975 85.5825 49.7925C86.895 49.9875 88.2225 50.535 89.0325 51.5925C89.6775 52.425 89.94 53.49 90.1125 54.5325C90.48 56.7675 90.51 59.055 90.2025 61.305C90.135 61.7775 90.0525 62.265 89.7825 62.6625C89.265 63.42 88.245 63.6075 87.3375 63.735C87.0675 67.4475 86.805 71.1675 86.535 74.88C86.475 75.6675 86.385 76.545 85.7925 77.07C85.4175 77.4 84.915 77.5275 84.4275 77.64C83.235 77.9025 82.0125 78.105 80.805 77.9925C79.785 77.9025 78.3 77.5275 77.6475 76.65C76.995 75.7725 77.1075 73.9425 77.0625 72.8625C76.935 69.795 76.8 66.735 76.6725 63.6675H76.6575Z"
        fill="url(#paint3_linear_6071_3440)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_6071_3440" x1="-1.65414e-05" y1="60.0001" x2="120" y2="60.0001"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#AAD3FF" />
        <stop offset="0.16" stop-color="#AAD3FF" stop-opacity="0.99" />
        <stop offset="0.29" stop-color="#ADD4FF" stop-opacity="0.95" />
        <stop offset="0.41" stop-color="#B2D7FF" stop-opacity="0.88" />
        <stop offset="0.52" stop-color="#B8DAFF" stop-opacity="0.79" />
        <stop offset="0.63" stop-color="#C0DEFF" stop-opacity="0.67" />
        <stop offset="0.74" stop-color="#CAE3FF" stop-opacity="0.52" />
        <stop offset="0.84" stop-color="#D6E9FF" stop-opacity="0.34" />
        <stop offset="0.94" stop-color="#E4F0FF" stop-opacity="0.14" />
        <stop offset="1" stop-color="#EEF5FF" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_6071_3440" x1="61.1159" y1="47.0156" x2="61.1159" y2="83.2821"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_6071_3440" x1="40.2217" y1="49.6707" x2="40.2217" y2="78.0243"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_6071_3440" x1="82.0417" y1="49.6707" x2="82.0417" y2="78.0243"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <clipPath id="clip0_6071_3440">
        <rect width="120" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>

</ng-template>

<ng-template #fullAudienceDarkTheme>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6071_3343)">
      <path
        d="M98.145 19.3951C107.317 23.8651 112.23 33.2401 115.837 42.6751C119.422 52.1101 121.695 61.5976 118.41 69.9076C115.147 78.2101 106.297 85.3501 95.9925 91.9651C85.6875 98.6026 73.92 104.753 62.43 104.475C50.91 104.198 39.69 97.5076 29.9775 90.7201C20.265 83.9551 12.06 77.0926 6.52498 68.5726C0.967484 60.0451 -1.92752 49.8676 1.43248 40.9501C4.81498 32.0326 14.46 24.3826 25.6275 20.3926C36.7725 16.4251 49.4775 16.1101 62.5275 15.7426C75.5775 15.3526 88.9725 14.9251 98.145 19.3951Z"
        fill="url(#paint0_linear_6071_3343)" />
      <path d="M33.9301 30.78C33.9901 30.555 34.1026 30.345 34.2601 30.165C34.1176 30.345 33.9976 30.555 33.9301 30.78Z"
        fill="#2B99FF" />
      <path
        d="M60.3825 46.6349C64.1436 46.6349 67.1925 43.586 67.1925 39.8249C67.1925 36.0638 64.1436 33.0149 60.3825 33.0149C56.6215 33.0149 53.5725 36.0638 53.5725 39.8249C53.5725 43.586 56.6215 46.6349 60.3825 46.6349Z"
        fill="#F045CB" />
      <path
        d="M53.6325 66.0751V66.0076C52.2375 66.3601 50.8275 65.2426 50.295 63.9076C49.7625 62.5726 49.86 61.0876 49.8675 59.6551C49.8675 57.8701 49.71 56.0701 49.95 54.3001C50.19 52.5301 50.8875 50.7376 52.275 49.6201C54.3525 47.9551 57.2925 48.2101 59.955 48.2101C61.6575 48.2101 63.3675 48.0826 65.0475 48.3376C66.7275 48.5926 68.4225 49.2901 69.465 50.6401C70.29 51.7051 70.6275 53.0701 70.845 54.4051C71.3175 57.2626 71.355 60.1951 70.9575 63.0601C70.875 63.6676 70.7625 64.2901 70.4175 64.7926C69.75 65.7601 68.4525 66.0001 67.29 66.1651C66.9525 70.9201 66.6075 75.6676 66.27 80.4226C66.195 81.4351 66.075 82.5526 65.3175 83.2201C64.8375 83.6401 64.1925 83.8051 63.57 83.9476C62.0475 84.2851 60.4875 84.5476 58.935 84.4051C57.63 84.2851 55.7325 83.8126 54.8925 82.6876C54.0525 81.5626 54.2025 79.2226 54.1425 77.8501C53.9775 73.9276 53.805 70.0051 53.64 66.0901L53.6325 66.0751Z"
        fill="url(#paint1_linear_6071_3343)" />
      <path
        d="M39.5175 49.6275C42.4584 49.6275 44.8425 47.2435 44.8425 44.3025C44.8425 41.3616 42.4584 38.9775 39.5175 38.9775C36.5766 38.9775 34.1925 41.3616 34.1925 44.3025C34.1925 47.2435 36.5766 49.6275 39.5175 49.6275Z"
        fill="#F045CB" />
      <path
        d="M34.245 64.8226V64.7701C33.1575 65.0476 32.055 64.1701 31.635 63.1276C31.215 62.0851 31.2975 60.9226 31.2975 59.7976C31.2975 58.4026 31.1775 56.9926 31.3575 55.6126C31.5375 54.2326 32.085 52.8226 33.18 51.9526C34.8 50.6551 37.1025 50.8501 39.1875 50.8501C40.515 50.8501 41.8575 50.7526 43.17 50.9476C44.4825 51.1426 45.81 51.6901 46.62 52.7476C47.265 53.5801 47.5275 54.6451 47.7 55.6876C48.0675 57.9226 48.0975 60.2101 47.79 62.4601C47.7225 62.9326 47.64 63.4201 47.37 63.8176C46.8525 64.5751 45.8325 64.7626 44.925 64.8901C44.655 68.6026 44.3925 72.3226 44.1225 76.0351C44.0625 76.8226 43.9725 77.7001 43.38 78.2251C43.005 78.5551 42.5025 78.6826 42.015 78.7951C40.8225 79.0576 39.6 79.2601 38.3925 79.1476C37.3725 79.0576 35.8875 78.6826 35.235 77.8051C34.5825 76.9276 34.695 75.0976 34.65 74.0176C34.5225 70.9501 34.3875 67.8901 34.26 64.8226H34.245Z"
        fill="url(#paint2_linear_6071_3343)" />
      <path
        d="M81.345 49.6275C84.2859 49.6275 86.67 47.2435 86.67 44.3025C86.67 41.3616 84.2859 38.9775 81.345 38.9775C78.4041 38.9775 76.02 41.3616 76.02 44.3025C76.02 47.2435 78.4041 49.6275 81.345 49.6275Z"
        fill="#F045CB" />
      <path
        d="M76.0651 64.8226V64.7701C74.9776 65.0476 73.8751 64.1701 73.4551 63.1276C73.0351 62.0851 73.1176 60.9226 73.1176 59.7976C73.1176 58.4026 72.9976 56.9926 73.1776 55.6126C73.3576 54.2326 73.9051 52.8226 75.0001 51.9526C76.6201 50.6551 78.9226 50.8501 81.0076 50.8501C82.3351 50.8501 83.6776 50.7526 84.9901 50.9476C86.3026 51.1426 87.6301 51.6901 88.4401 52.7476C89.0851 53.5801 89.3476 54.6451 89.5201 55.6876C89.8876 57.9226 89.9176 60.2101 89.6101 62.4601C89.5426 62.9326 89.4601 63.4201 89.1901 63.8176C88.6726 64.5751 87.6526 64.7626 86.7451 64.8901C86.4751 68.6026 86.2126 72.3226 85.9426 76.0351C85.8826 76.8226 85.7926 77.7001 85.2001 78.2251C84.8251 78.5551 84.3226 78.6826 83.8351 78.7951C82.6426 79.0576 81.4201 79.2601 80.2126 79.1476C79.1926 79.0576 77.7076 78.6826 77.0551 77.8051C76.4026 76.9276 76.5151 75.0976 76.4701 74.0176C76.3426 70.9501 76.2076 67.8901 76.0801 64.8226H76.0651Z"
        fill="url(#paint3_linear_6071_3343)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_6071_3343" x1="-1.65414e-05" y1="60.0001" x2="120" y2="60.0001"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#00419E" />
        <stop offset="0.12" stop-color="#023E95" stop-opacity="0.93" />
        <stop offset="0.34" stop-color="#08397F" stop-opacity="0.76" />
        <stop offset="0.62" stop-color="#112F5B" stop-opacity="0.47" />
        <stop offset="0.94" stop-color="#1E232A" stop-opacity="0.07" />
        <stop offset="1" stop-color="#212121" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_6071_3343" x1="60.5234" y1="48.1782" x2="60.5234" y2="84.4447"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_6071_3343" x1="39.6292" y1="50.8257" x2="39.6292" y2="79.1793"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_6071_3343" x1="81.4493" y1="50.8257" x2="81.4493" y2="79.1793"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F244C9" />
        <stop offset="1" stop-color="#0089FF" />
      </linearGradient>
      <clipPath id="clip0_6071_3343">
        <rect width="120" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
</ng-template>