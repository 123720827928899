<coreui-card class="card">
  <div class="builder-header">
      <div class="count-background">
          <ng-container *ngTemplateOutlet="countTemplate"></ng-container>
      </div>
      <div class="update-count-container">
          <coreui-notification notificationStyle="success" #countUpdated>
              The update count has been successfully initiated. Depending on the complexity of your criteria this make
              take
              some time, feel free to check back later.
          </coreui-notification>
          <button libThrottleClick [throttleTime]="2000" (throttledClick)="updateCount(countUpdated)"
              coreUIButton="secondary" withIcon="left"
              *ngIf="(countsService.countUpdating$ | async) === false && (errorOnLoadState$ | async) === false && !audience"
              [disabled]="isSaveButtonDisabled() && !audience && (countsService.builderCount$ | async)?.displayCount === 'N/A' " >
              <i coreUIIcon="refresh"></i>Update Count
          </button>
          <button class="cancel-count-container" coreUIButton="secondary" *ngIf="countsService.countUpdating$ | async"
              (click)="cancelCount()">
              Cancel Count
          </button>
          <div *ngIf="(isActivityPage || !isProductTypeDcdp) && (audience && (audience.audienceDefinitionId || audience.audienceListId))">
            <button coreUIButton="secondary" withIcon="right" (click)="currentAudienceDefinition()">
             {{ audience.audienceDefinitionId ? 'Go to Current Audience Definition' : 'Go to List' }}<i coreUIIcon="doubleChevronRight"></i>
            </button>
          </div>
            <div class="count-date" *ngIf="((countsService.builderCount$ | async)?.displayCount !== 'N/A' || (countsService.countUpdating$ | async)) && (errorOnLoadState$ | async) === false">
              <coreui-badge color="Slate" [filled]="false" [border]="false"
                  *ngIf="countsService.countUpdating$ | async; else countUpdatedBadge">Updating count...
              </coreui-badge>
              <ng-template #countUpdatedBadge>
                  <div class="countUpdated"
                      *ngIf="(countsService.builderCount$ | async)?.status === 'FAILURE'; else countUpdatedOn">
                      <ng-template #errorMessage>
                          <span class="countErrorMessage">
                              {{ (countsService.builderCount$ | async)?.errorMessage }}
                          </span>
                      </ng-template>
                      <coreui-badge color="Apple" [filled]="true" [hasIcon]="true" [border]="false"
                          [coreUITooltip]="errorMessage">
                          <i coreUIIcon="errorShield">
                          </i>Error
                      </coreui-badge>
                      <div class="text-style-muted-3">  {{ (countsService.builderCount$ |
                          async)?.countUpdatedOn |
                          date:
                          'MM/dd/YY hh:mm a': 'UTC' | coreUILocaleDate }} UTC </div>
                    </div>
                    <ng-template #countUpdatedOn>
                        <coreui-badge color="Lime" [filled]="false" [border]="false">
                            <span>Count Updated: {{
                                (countsService.builderCount$ | async)?.countUpdatedOn
                                | date: "short"
                                }}</span>
                        </coreui-badge>
                    </ng-template>
                </ng-template>
            </div>
        </div>
       
    </div>
    <ng-template #countTemplate>
        <div class="count-card">
            <div class="outer">
                <div class="inner">
                    <i coreUIIcon="userProfile" class="card-icon" scale="3"></i>
                </div>
            </div>
            <div>
                <h4 class="card-title">
                  {{ isDefinitionFlow ? 'Definition Count' : 'List Count' }}
                    <span class="text-style-muted-1" >({{ getDedupType() }})</span>
                </h4>
                <div>
                    <div class="card-value"
                        [class.count-na]="(countsService.builderCount$ | async)?.displayCount === 'N/A'" *ngIf="isEdit">
                        <coreui-load-mask [active]="countsService.countUpdating$ | async">
                            <i *ngIf='(((countsService.builderCount$ | async)?.displayCount === "N/A") || ((countsService.builderCount$ | async)?.status === "FAILURE") || ((countsService.builderCount$ | async)?.displayCount && !isEdit)) else displayBuilderCount'
                                class="noCount" coreUIIcon="tableBrief" scale="1.5"></i>
                                <ng-template #displayBuilderCount>
                                <span class="builder-count">
                                {{
                                ((countsService.builderCount$ | async)?.displayCount
                                | number: "1.0-0")
                                }}
                            </span>
                            </ng-template>
                        </coreui-load-mask>
                    </div>
                    <div class="card-value" *ngIf="!isEdit">{{ (audience?.['idCount'] | number: "1.0-0") || "&mdash;" }}</div>
                </div>
            </div>
        </div>
    </ng-template>
</coreui-card>
<coreui-modal modalWidth="40" #updateCancleCountModel>
<ng-template #titleTemplate>
  <span coreUIIcon="warning" class="Modal-titleIcon"></span> Your Progress Will Be Lost!
</ng-template>
<ng-template #titleCloseButtonTemplate>
  <button type="button" (click)="updateCancleCountModel.hide()" coreUIButton="icon-large">
    <i coreUIIcon="close"></i>
  </button>
</ng-template>
<ng-template #contentTemplate>
  <p>
    All progress will be lost if you cancel the audience count.
  </p>
  <p>
    Are you sure you want to continue?
  </p>
</ng-template>

<ng-template #buttonsTemplate>
  <button coreUIButton="secondary" (click)="toggleCancel('cancel')">
    Cancel
  </button>
  <button coreUIButton (click)="toggleCancel('continue')">
    Continue
  </button>
</ng-template>
</coreui-modal>