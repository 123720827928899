export enum DataType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DATE_TIME= 'datetime',
  BOOLEAN = 'boolean',
  AUDIENCE_LIST = 'audiencelist',
  MONITOR = 'monitor',
  AUDIENCE_DEFINITION = 'audiencedefinition',
  INCLUDE_ICON = 'include',
  EXCLUDE_ICON = 'exclude'
}

export enum DataSetType {
  AUDIENCE_LIST_CATEGORY = 'AUDIENCE_LIST_CATEGORY',
  AUDIENCE_LIST = 'AUDIENCE_LIST',
  AUDIENCE_DEFINITION_CATEGORY = 'AUDIENCE_DEFINITION_CATEGORY',
  AUDIENCE_DEFINITION = 'AUDIENCE_DEFINITION',
  DATA_SET_CATEGORY = 'DATA_SET_CATEGORY',
  DATA_SET = 'DATA_SET',
  DATA_SET_ATTRIBUTE = 'DATA_SET_ATTRIBUTE',
}
