<div class="welcome-page">
  <ng-container *ngTemplateOutlet="AudienceAITag"> </ng-container>
  <div class="welcome-container">
    <div class="welcome-content">
      <h1 class="welcome-ai">Welcome to Audience AI.</h1>
      <div>Effortlessly create your ideal audience using natural language.</div>
      <div class="card-block">
        <ng-container *ngFor="let card of welcomeCards">
          <coreui-card
            class="welcome-card"
            [header]="card.header"
            [text]="card.text"
            [icon]="card.icon"
          ></coreui-card>
        </ng-container>
      </div>
    </div>
    <div class="welcome-content">
      <div class="ai-message">
        <ng-container *ngTemplateOutlet="AssistantIcon"></ng-container>
        <div *ngIf="isQueryRunning">
          <ng-container *ngTemplateOutlet="typingMessage"></ng-container>
        </div>
        <div *ngIf="!isQueryRunning" class="chat-message text-style-3">
          {{ chatContent }}
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="chatInput; context: { $implicit: inputContext }"
      ></ng-container>
    </div>
  </div>
</div>
