import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { ModalComponent } from '@epsilon/core-ui';

@Component({
  selector: 'lib-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.sass'
})
export class ConfirmationPopupComponent {
  @ViewChild('alertModal', { static: true })
  private alertModal: ModalComponent;

  @ViewChild('alertModalButton', { read: ElementRef })
  public alertModalElementRef: ElementRef<HTMLButtonElement>;


  @Input() confirmationMessage = 'You will lose any work you\'ve done if you continue to leave this page. Are you sure you want to proceed?';
  @Input() title = 'You have unsaved changes!';
  @Input() confirmButtonText = 'Discard & Continue';
  @Input() cancelButtonText = 'Back to Editing';
  @Input() confirmButtonType: 'primary' | 'secondary' = 'primary';
  
  @Output() confirmEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();

  public async closeAlertModal(): Promise<void> {
    await this.alertModal.hide();
    if (this.alertModalElementRef?.nativeElement) {
      this.alertModalElementRef.nativeElement.focus();
    }
  }

  public async launchAlertModal(): Promise<void> {
    await this.alertModal.show(this.alertModalElementRef);
  }

  public confirm(): void {
    this.confirmEvent.emit();
    this.closeAlertModal();
  }

  public cancel(): void {
    this.cancelEvent.emit();
    this.closeAlertModal();
  }
}
