/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AutocompleteComponent, FilterComponent } from '@epsilon/core-ui';
import { AssetType, SaveViewFilterComponent } from '@epsilon-cdp/pcm-common-lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from '../../../reducers';
import {selectContext} from '../../../context/context.reducer';
import { AudienceStatus } from '../../../audience/audience.models';
import { MonitorTableSubType, TableType } from '../../audience-overview.models';
import { AUDIENCE_TXT, UI_STATUS_COMPLETE, UI_STATUS_ERROR, UI_STATUS_PROCESSING } from '../../../utils/utils';
import { TableFilterService } from '../table-filter.service';
import { MonitorTableComponent } from '../monitor-table/monitor-table.component';
import { CabConstants } from '../../../cab.constants';
import { FeatureService } from '../../../utils/feature-service';
import { FEATURE_ASSET_LISTING, FEATURE_SAVED_SEARCH, FEATURE_TAGS_FILTERING } from '../../../utils/feature-utils';
import { UtilsService } from '../../../utils/utilservice';

const defaultTableType = [{
  name: 'Paid',
  status: 'paid',
  active: true
},
{
  name: 'Owned',
  status: 'owned',
  active: false
}];

@UntilDestroy()
@Component({
  selector: 'lib-overview-header',
  templateUrl: './overview-header.component.html',
  styleUrls: ['./overview-header.component.sass'],
})
export class OverviewHeaderComponent implements OnInit {
  @ViewChild('saveViewFilterComponent', { static: false }) saveViewFilterComponent: SaveViewFilterComponent;
  @ViewChild('statusFilter') public statusFilter: FilterComponent;
  @ViewChild('audienceFilter') public audienceFilter: FilterComponent;
  @ViewChild('jobTypeFilter') public jobTypeFilter: FilterComponent;
  @ViewChild('scheduleTypeFilter') public scheduleTypeFilter: FilterComponent;
  @ViewChild('monitorTable') public monitorTable: MonitorTableComponent;
  @ViewChild('dateFilter')  public dateFilter: FilterComponent;
  @ViewChild('updatedDateFilter')  public updatedDateFilter: FilterComponent;
  @Output() deleteActionForMultiple: EventEmitter<any> = new EventEmitter<any>();
  @Input() ismonitorScheduleTab = false;
  tableType$: BehaviorSubject<TableType>;
  monitorTableSubType$: BehaviorSubject<MonitorTableSubType>;
  dateTypeLabel = 'All Time';
  statuses: AudienceStatus[] = [UI_STATUS_PROCESSING, UI_STATUS_COMPLETE, UI_STATUS_ERROR];
  audienceDisplayNameTypes;
  jobTypes: any[] = [{name: 'Export Definition', value: 'AudienceDefinition'}, {name: 'Export List', value: 'AudienceList'}];
  scheduleTypes: any[] = [{name: 'Export Definition', value: 'EXTRACT_FROM_DEFINITION'}, {name: 'Create List', value: 'AUDIENCE_LIST'}];
  dateTypes: any[] = [{name: 'All time', value : ''}, {name:'Today', value: 'today'}, {name: 'Yesterday',value:'yesterday'},
    {name:'Last 3 days', value:'recent3Days'}, {name:'Last 7 days', value:'recent7Days'},  {name:'Last 30 days', value:'recent30Days'}];
  formGroup: UntypedFormGroup;
  displayFiltersForDefinitionsAndList: boolean;
  productType: string;
  sendToTargetType$: BehaviorSubject<string>;
  tableType = defaultTableType;
  enableUIADHActivityListing = false;
  isShowFeedTable = false;
  assetTypeForFilter: AssetType;
  assetTypeFilterVisible = false;
  allowTagsEnabled = false;
  saveSearchEnabled = false;
  isPaidchannelTypeFeatureEnabled = true;

  items = [];
  @ViewChild(AutocompleteComponent, { static: true })
  public autocomplete: AutocompleteComponent;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AssetType = AssetType;
  selectedTags: string[] = [];
  searchId: string;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public tableFilterService: TableFilterService,
    public store: Store<AppState>,
    public featureService: FeatureService,
    private utilsService: UtilsService
  ) {
    this.formGroup = tableFilterService.formGroup;
    this.formGroup.addControl('filterDate', new UntypedFormControl(''));
    this.formGroup.addControl('updatedDateFilterDate', new UntypedFormControl(''));
    this.tableType$ = tableFilterService.tableType$;
    this.sendToTargetType$ = tableFilterService.sendToTargetType$;
    this.monitorTableSubType$ = tableFilterService.monitorTableSubType$;
    this.allowTagsEnabled = this.featureService.isFeatureEnabled(FEATURE_TAGS_FILTERING) && this.featureService.isFeatureEnabled(FEATURE_ASSET_LISTING);
    this.saveSearchEnabled = (this.featureService.isFeatureEnabled(FEATURE_SAVED_SEARCH) && this.featureService.isFeatureEnabled(FEATURE_ASSET_LISTING));
    this.tableType$.subscribe((tableType) => {
      if(tableType === AUDIENCE_TXT) {
      this.audienceDisplayNameTypes = tableFilterService.audienceDisplayNameTypes.filter( type => type.name !== 'Prospects');
      } else {
        this.audienceDisplayNameTypes = tableFilterService.audienceDisplayNameTypes;
      }
      this.assetTypeFilterVisible = false;
      this.assetTypeForFilter = this.getAssetTypeForFilter(tableType);
      this.dateTypeLabel = this.dateTypes[0].name;
      this.formGroup.get('updatedDateFilterDate').setValue('');
      this.formGroup.get('filterDate').setValue('');
      this.tableFilterService.onDateFilterClick('');
      setTimeout(() => {
        this.assetTypeFilterVisible = this.allowTagsEnabled;
      });
    });

    this.tableFilterService.tags$.subscribe((tags: string[]) => {
      this.selectedTags = tags;
    });
    
    this.tableFilterService.searchId$.subscribe(searchId => {
      this.searchId = searchId;
    });
  }

  onSavedViewApplyClick(selectedItem){
    if(selectedItem === 'reset') {
      this.resetSaveSearchFilter();
    } else {
      const tagObject = selectedItem.filter.find(item => item.attribute === 'tags.tag');
      this.selectedTags = tagObject ? tagObject.values : [];
      this.tableFilterService.searchSavedFilter$.next(selectedItem);
      this.dateTypeLabel = "All time";
    }
  }

  resetSaveSearchFilter() {
    this.tableFilterService.searchSavedFilter$.next('reset');
    this.tableFilterService.tags$.next([]);
    this.formGroup.get('search').setValue('');
    this.dateTypeLabel = "All time";
  }

  getAssetTypeForFilter(tableType: string): AssetType {
    const assetTypeForFilterBasedOnTable = {
      audience: () => {
        return AssetType.AUDIENCE_LIST;
      },
      definition: () => {
        return AssetType.AUDIENCE_DEFINITION;
      },
      deliveries: () => {
        return AssetType.AUDIENCE_ACTIVATION;
      },
      default: () => '',
    };
    return (
      assetTypeForFilterBasedOnTable[tableType] ||
      assetTypeForFilterBasedOnTable['default']
    )();
  }

  ngOnInit() {
    this.store
      .select(selectContext)
      .subscribe((context) => {
        this.productType = context?.productType;
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.displayFiltersForDefinitionsAndList = true;
        }
        if (context?.enableUIADHActivityListing) {
          this.enableUIADHActivityListing = true;
        }
      });
    if(this.ismonitorScheduleTab){
      this.jobTypes.forEach(element => {
        if(element.value === 'AudienceList')
        {
          element.name = 'Create List';
        }
      });
    }
    this.sendToTargetType$.subscribe((event)=>{
      this.tableType.forEach(x => x.active = false);
      const tableType = this.tableType.find(x => x.status === event);
      if(tableType){
        tableType.active =  true;
        this.tableType = [...this.tableType];
      }
      if(event === 'paid' && !this.isPaidchannelTypeFeatureEnabled){
        this.tableFilterService.onTargetTypeClick('owned')
        return;
      }
      this.isShowFeedTable = event === 'owned' && this.enableUIADHActivityListing;
    })
    this.getPaidChannelFlag();

    this.tableFilterService.statuses$
      .pipe(untilDestroyed(this)).subscribe((statuses: any[]) => {
        const statuesFormValues = [];
        this.statuses.forEach(status => {
          if (statuses.includes(status)) statuesFormValues.push(true);
          else statuesFormValues.push(false);
        });
        this.formGroup.get('statuses').setValue(statuesFormValues);
      });
  }

  public onStatusApplyClick() {
    this.tableFilterService.onStatusApplyClick();
    this.statusFilter.hideFilter();
  }

  public getPaidChannelFlag() {
    this.utilsService.isPaidChanelFeatureEnabled().pipe(untilDestroyed(this))
      .subscribe((isPaidChannelTypeEnabled) => {
        this.isPaidchannelTypeFeatureEnabled = isPaidChannelTypeEnabled;
      });
  }

  public onDateFilterClick(filterDate: string, filterLabel: string ): void {
    this.formGroup.get('filterDate').setValue(filterDate);
    this.dateTypeLabel = filterLabel;
    this.tableFilterService.onDateFilterClick(filterDate);
    this.dateFilter.hideFilter();
}

  public get selectedDateType(): string {
    return this.formGroup.get('filterDate').value;
  }

  public get selectedUpdateDateType(): string {
    return this.formGroup.get('updatedDateFilterDate').value;
  }

  public onJobTypeApplyClick() {
    this.tableFilterService.onJobTypeApplyClick();
    this.jobTypeFilter.hideFilter();
  }

  getSelectedTags(event) {
    if(!event?.length) {
      this.saveViewFilterComponent?.resetSaveSearchFilter();
      this.tableFilterService.onTagsApplyClick(event);
      return;
    }
    this.selectedTags = event;
    this.tableFilterService.onTagsApplyClick(event);
  }

  public onScheduleTypeApplyClick() {
    this.tableFilterService.onScheduleTypeApplyClick();
    this.scheduleTypeFilter.hideFilter();
  }

  public checkedAll(event)
  {
    if(event.target.checked === true){
      this.tableFilterService.checkedAllStatus = true;
      this.tableFilterService.rowSelected = 25;
    }
    else if(event.target.checked === false){
      this.tableFilterService.checkedAllStatus = false;
      this.tableFilterService.rowSelected = 0;
    }
  }
  onJobTypeClearClick() {
    this.formGroup.get('jobTypes').setValue([false, false]);
  }

  onScheduleTypeClearClick() {
    this.formGroup.get('scheduleTypes').setValue([false, false]);
  }

  onStatusClearClick() {
    this.formGroup.get('statuses').setValue([false, false, false]);
  }

  onAudienceDisplayNameTypeClearClick() {
    this.formGroup.get('audienceDisplayNameTypes').setValue([false, false, false, false]);
  }

  onAudienceDisplayNameTypeApplyClick() {
      this.tableFilterService.onAudienceDisplayNameTypeApplyClick();
      this.audienceFilter.hideFilter();
  }


    // action from trash can icon
  public deleteActionMultiple() {
      this.deleteActionForMultiple.emit();
  }

  onUpdatedDateFilterClick(filterDate: string, filterLabel: string) {
    this.formGroup.get('updatedDateFilterDate').setValue(filterDate);
    this.dateTypeLabel = filterLabel;
    this.tableFilterService.onDateFilterClick(filterDate);
    this.updatedDateFilter.hideFilter();
  }
}
