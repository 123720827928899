import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-audience-ai-welcome',
  templateUrl: './audience-ai-welcome.component.html',
  styleUrl: './audience-ai-welcome.component.sass'
})
export class AudienceAIWelcomeComponent implements OnChanges {
  @Input() AudienceAITag!: TemplateRef<any>;
  @Input() AssistantIcon!: TemplateRef<any>;
  @Input() typingMessage!: TemplateRef<any>;
  @Input() chatInput!: TemplateRef<any>;
  @Input() chatContent: string;
  @Input() inputContext!: {
    audienceAIFormGroup: FormGroup;
    isQueryRunning: boolean;
    isUserInputValid: boolean;
    submitQuery: () => void;
  };

  isQueryRunning: boolean;

  welcomeCards = [
    {
      header: 'Explore Your Data',
      text: 'Share your use case with us and let our AI recommend the perfect audience attributes.',
      icon: 'forecasting'
    },
    {
      header: 'Build Your Perfect Audience',
      text: 'Got a clear target in mind? Describe them in your own words and our AI will pinpoint the perfect criteria and attributes for you.',
      icon: 'audiences'
    },
    {
      header: 'Data Limitations',
      text: 'Currently, our system builds audiences using Profile, Sales data, and TSP (Total Source Plus).',
      icon: 'warning'
    }
  ];

  ngOnChanges(): void {
    this.isQueryRunning = this.inputContext?.isQueryRunning;
  }
}
