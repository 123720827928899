import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from '../../../reducers';
import {
  Audience,
  AudienceDefinition,
} from '../../../audience/audience.models';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import {
  AttributeDetails,
  BuilderAudience,
} from '../../../audience-builder/audience-builder.models';
import { DataTypeOperator } from '../../../models/data-type-operator';
import { convertAudienceDefinitionToBuilder } from '../../../audience/audience.utils';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { DetailsModalService } from '../../../services/details-modal.service';
import { CampaignExtract } from '../../../campaign-extract/campaign-extract.model';
import { ActivatedRoute, Router } from '@angular/router';
import { selectAudiences } from '../../../audience/audience.reducer';
import { selectContext } from '../../../context/context.reducer';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { UtilsService } from '../../../utils/utilservice';
import { AUDIENCE_TXT, DEFINITION_TXT } from '../../../utils/utils';
import { Action } from '../../../feature-access/feature.access.action';


@UntilDestroy()
@Component({
  selector: 'lib-detail-body',
  templateUrl: './detail-body.component.html',
  styleUrls: ['./detail-body.component.sass'],
})
export class DetailBodyComponent implements OnInit, OnChanges {
  @Input() dataTypeOperators: DataTypeOperator[];
  @Input() dataUniverse: DataUniverse;
  @Input() audience: Audience | AudienceDefinition | CampaignExtract;
  @Input() isAudience: boolean; // audience vs definition
  @Input() dedupeDisplayName: string;
  @Input() attributeDetails: AttributeDetails;
  @Input() isEdit: boolean;
  @Output() currentAudience = new EventEmitter<any>();
  contextId: string;
  isMoniter = false;
  productType: string;
  cabAttributes$ = this.store.select(selectAudiences);

  builderAudience: BuilderAudience;

  constructor(
    public store: Store<AppState>,
    public builderService: AudienceBuilderService,
    public detailsModalService: DetailsModalService,
    private route: ActivatedRoute,
    public featureAccessService: FeatureAccessService,
    private router: Router,
    private utilsService: UtilsService,
  ) {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
      });
  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataTypeOperators']) {
      this.dataTypeOperators = changes['dataTypeOperators'].currentValue;
    }
    if (changes['dataUniverse']) {
      this.dataUniverse = changes['dataUniverse'].currentValue;
    }
    if (changes['attributeDetails']) {
      this.attributeDetails = changes['attributeDetails'].currentValue;
    }
    if (changes['audience']) {
      this.audience = changes['audience'].currentValue;
      this.builderAudience = convertAudienceDefinitionToBuilder(this.audience);
    }
  }

  getChannelType() {
    return this.audience?.['audienceAttributes'].channelType;
  }

  onDisplayNameHandler() {
    if (this.audience?.audienceListId || (this.productType !== 'DCDP' && !this.isAudience)) {
      this.router.navigate([
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.audience.dataUniverseId,
        this.audience.dataType === 'AudienceDefinition'
          ? DEFINITION_TXT
          : AUDIENCE_TXT,
        'view',
        this.audience.dataType === 'AudienceDefinition'
          ? this.audience.audienceDefinitionId
          : this.audience.audienceListId,
      ], {queryParams: {isDCDP: this.productType === 'DCDP' ? true : false}});
    } else {
      this.router.navigate([
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.audience.dataUniverseId,
        'builder',
        this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE) ? 'edit' :'view',
        this.audience.audienceDefinitionId
      ]);
    }
  }
}
