<coreui-breadcrumbs aria-label="Breadcrumb">
  <a (click)="routeToEnumerations()" coreUIBreadcrumbLink>Enumerations</a>
  <span coreUIBreadcrumbSlash></span>
  <span coreUIBreadcrumbEnd>{{enumerationBreadCrumbName}}</span>
</coreui-breadcrumbs>
<div class="enum-container">
  <h1 class="enum-h1">{{enumerationBreadCrumbName}}</h1>
</div>
<coreui-tab-group>
  <coreui-tab [label]="!isEditEnum ? 'Create enumeration' : enumerationBreadCrumbName" [active]="true">
    <form class="enumForm" [formGroup]="enumerationForm">
      <coreui-form-field *ngIf="!isEditEnum" label="Enumeration name">
        <input coreUIInput formControlName="enumerationName"/>
        <span class="font-size-sm">Name this enumeration and you can assign this enumeration to attributes later.</span>
        <coreui-form-error>
          This field is required
        </coreui-form-error>
      </coreui-form-field>
      <coreui-form-field label="Description (optional)">
        <textarea rows="5" coreUIInput formControlName="description" placeholder="Enumeration Description"></textarea>
      </coreui-form-field>
      <coreui-form-field label="Data type">
        <coreui-select formControlName="enumerationDataType" placeholder="Select" [disabled]="isEditEnum">
          <coreui-option *ngFor="let item of enumerationDataTypes" [value]="item">{{item}}</coreui-option>
        </coreui-select>
      </coreui-form-field>
      <div coreUIDivider></div>
      <h4 class="importValues">Set up enumerated values</h4>
      <coreui-form-tab-group>
        <coreui-form-tab label="Import files" [active]="true">
          <div class="importFile">
            <span>Create a separate column in files to define displayed names</span>
            <coreui-notification notificationStyle="error" [isPersistent]="false" #fileUploadError>
              File failed to upload.
            </coreui-notification>
            <div class="Core-Form uploadForm">
                <coreui-file-upload-drop-input>
                  <input coreUIFileUploadInput formControlName="uploadEnumValues" name="uploadEnumValues" type="file" (click)="uploadEnumValuesFromFile.value = null" (change)="onUploadFileStateChanges($event)" #uploadEnumValuesFromFile/>
                  Drop file here or
                  <a>browse</a>
                </coreui-file-upload-drop-input>
              <div *ngIf="enumValuesFile" class="enumValuesFile">
                <span>{{this.enumValuesFileName}}</span>
                <span class="enumValuesFileSize">{{this.enumValuesFileSize}}</span>
                <i coreUIIcon="trash" scale="1.5" class="trash" (click)="removeEnumValueFile()"></i>
              </div>
            </div>
            <coreui-radio-group orientation="horizontal">
              <coreui-form-field label=" Append file to value list">
                <input type="radio" coreUIRadio value="appendValue" name="enumValuesFromFileAppendOrReplace"  formControlName="enumValuesFromFileAppendOrReplace"/>
              </coreui-form-field>
              <coreui-form-field label=" Replace value list with file">
                <input type="radio" coreUIRadio value="replaceValue" name="enumValuesFromFileAppendOrReplace" formControlName="enumValuesFromFileAppendOrReplace"/>
              </coreui-form-field>
            </coreui-radio-group>
          </div>
          <button coreUIButton (click)="saveEnumerationFromFile()">
            Save
          </button>
          <button coreUIButton="text-primary" (click)="cancelEnumerationFromFile()">
            Cancel
          </button>
        </coreui-form-tab>
        <coreui-form-tab label="Enter values">
          <coreui-form-field label="Enter enumerated values">
            <div class="ListBuilder-wrapper">
              <input type="text" coreUIInput withIcon placeholder="Enter item name" name="input" formControlName="enumeratedValueInput" (keyup.enter)="addItem()" />
              <i coreUIIcon="add" (click)="addItem()"></i>
            </div>
            <div *ngIf="enumeratedValueExisted" class="enum-value-error">
              The value is existed. Please add a different value
            </div>
            <coreui-selected-items>
              <ng-template #footer>
                <div class="Core-SelectedItems-showMoreLess" *ngIf="enumeratedValues.length > 3">
                  <a (click)="onShowHide()" class="Core-SelectedItems-showHide">{{showHideText}}</a>
                </div>
              </ng-template>
              <ng-container class="enum-val-container" *ngFor="let item of displayableItems | async; let i = index">
                <coreui-selected-item class="u-marginTop15" (removed)="onRemoveItem(i)">{{item.id}}
                  <input class="enum-input-val" [value]="item.displayName" (change)="updateEnumVal($event, i)"/></coreui-selected-item>
              </ng-container>
            </coreui-selected-items>
          </coreui-form-field>
          <button coreUIButton (click)="saveEnumeration()">
            Save
          </button>
          <button coreUIButton="text-primary" (click)="cancelEnumeration()">
            Cancel
          </button>
        </coreui-form-tab>
      </coreui-form-tab-group>
    </form>
  </coreui-tab>
</coreui-tab-group>
<coreui-notification [notificationStyle]="notificationStyle" #enumCreationSuccess>
  {{enumerationForm.get('enumerationName').value}} {{creationResultMessage}}
</coreui-notification>
<coreui-notification [notificationStyle]="notificationStyle" #enumCreationFromFileError>
  {{creationResultMessage}}
</coreui-notification>
