<coreui-loading-indicator label="Working..."
  [active]="(loadingService.isLoadingFullScreen$ | async) || (loadingService.isLoading$ | async)">
</coreui-loading-indicator>

<coreui-notification
  #generalErrorNotification
  notificationStyle="error"
  [isPersistent]="false"
>
  There was an error while attempting your action. Please try again later.
</coreui-notification>

<coreui-notification
  notificationStyle="error"
  [autoShow]="loadingService.isEntityNotFound$ | async"
  [isPersistent]="false"
>
  Entity not found
</coreui-notification>

<coreui-notification
  notificationStyle="error"
  [autoShow]="loadingService.isServerNotFound$ | async"
  [isPersistent]="false"
>
  {{ loadingService.serverErrorMessage$.getValue() }}
</coreui-notification>

<div class="Core-SiteContent">
  <main class="Core-SiteContent-main" [ngClass]="!isProductTypeDcdp && 'epcc-container'">
    <router-outlet></router-outlet>
    <lib-revision-info></lib-revision-info>
  </main>
</div>

 <lib-discard-changes [show]="showDiscardChanges" (onEditOrDiscardEvent)="onDiscardEventChanges($event)"></lib-discard-changes>
