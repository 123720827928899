<lib-profile-section [audience]="audience" [dedupeDisplayName]="dedupeDisplayName" (emitCurrentAudience)="onDisplayNameHandler()"></lib-profile-section>
<section class="component-wrapper" *ngIf="dataTypeOperators.length && !!builderAudience">
  <div class="list-details-toggle">
    <h2>Definition Criteria</h2>
    <lib-segment-path-toggle toggleLabel="Show full path"></lib-segment-path-toggle>
  </div>
  <div class="include-exclude-container" >
    <div  class="body-header-container" *ngIf="builderAudience?.includeConditions?.length > 0">
      <div class="iconHeader">
        <lib-type-icon [type]="'include'" > </lib-type-icon>
        <h4>I want to include...</h4>
      </div>
    </div>

    <div *ngIf="builderAudience?.includeConditions">
      <lib-detail-node [cabAttributes]="cabAttributes$ | async" [dataTypeOperators]="dataTypeOperators"
        [nodes]="builderAudience?.includeConditions" [attributeDetails]="attributeDetails">
      </lib-detail-node>
    </div>
    <div class="body-header-container exclude" *ngIf="builderAudience?.excludeConditions?.length > 0">
      <div class="iconHeader">
        <lib-type-icon [type]="'exclude'"> </lib-type-icon>
        <h4>I want to exclude...</h4>
      </div>
    </div>

    <div *ngIf="builderAudience?.excludeConditions">
      <lib-detail-node [cabAttributes]="cabAttributes$ | async" [dataTypeOperators]="dataTypeOperators"
        [nodes]="builderAudience?.excludeConditions" [attributeDetails]="attributeDetails">
      </lib-detail-node>
    </div>
  </div>
    
</section>