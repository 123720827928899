import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter as _filter, find, findIndex, forEach, get, last, reject, upperCase } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { DropListDirective } from '@epsilon/core-ui';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { EnumSetValues } from '../admin/admin.models';
import {
  AudienceBooleanType,
  AudienceDefinitionGenerateAIRating,
  AudienceExpressionGroup,
  AudienceRuleGroup,
  BuilderAttribute,
  BuilderAudienceDataType,
  emptyAudienceRuleGroup
} from '../audience-builder/audience-builder.models';
import { AdminService } from '../admin/admin.service';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CountsService } from '../counts/counts.service';
import { DragDropService } from '../services/drag-drop.service';
import { hasAggregationGroup, hasExpressionGroup } from '../audience/audience.utils';
import { FEATURE_NOT_CONDITION } from '../utils/feature-utils';
import { BUILDER_TXT, getStoreState } from '../utils/utils';
import { DataType } from '../enums/data-types';
import { FeatureService } from '../utils/feature-service';
import { AudienceService } from '../audience/audience.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr-CA';

@UntilDestroy()
@Component({
  selector: 'lib-builder-group',
  templateUrl: './builder-group.component.html',
  styleUrls: ['./builder-group.component.sass'],
})
export class BuilderGroupComponent implements OnInit, AfterViewInit {
  @Input() ruleGroup: AudienceRuleGroup[][];
  @Input() nestLevel = 0;
  @Input() parent;
  @Input() parentExpressionIdx: number;
  @Input() dedupeType: UntypedFormControl;
  @ViewChildren(DropListDirective) dropLists?: QueryList<DropListDirective>;
  @ViewChild('generateAIPrompt') generateAIPrompt;
  booleanOperators: AudienceBooleanType[];
  isDragging$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dropDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  definitionIsNested$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  aggregatorDropBox: boolean;
  enableAddAgrregationBoxOnDelete: boolean;
  isAggregatorDropped: boolean;
  attributeEnumValues: {[key: string]: EnumSetValues[] | null } = {};
  queryFromTextAI: boolean;
  isBuilderView:boolean

  constructor(
    public store: Store<AppState>,
    public countsService: CountsService,
    public dragDropService: DragDropService,
    public builderService: AudienceBuilderService,
    public adminService: AdminService,
    public audienceService: AudienceService,
    private route: ActivatedRoute,
    public featureService: FeatureService
  ) {
    this.isBuilderView = this.route.snapshot.url.map(item=>item.path).join('/').includes(`${BUILDER_TXT}/view`);
    registerLocaleData(localeFr);
  }

  ngOnInit(): void {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.booleanOperators = this.featureService.isFeatureEnabled(FEATURE_NOT_CONDITION) ? ['AND', 'OR'] : ['AND', 'OR'];
        this.queryFromTextAI =  context?.metadata['audience.definition.query.from.text'];
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dropLists) {
        // Registers the builder group drop lists
        this.dropLists.changes.subscribe(() => {
          this.dragDropService.register(
            this.dropLists.toArray().map((dropList) => dropList.id)
          );
        });
      }
    }, 1000);
  }

  submitFeedback(userRating, genAIRequestId) {
    const generateFromTextRequest: AudienceDefinitionGenerateAIRating = {
      cabContextId: this.route.snapshot.paramMap.get('contextId'),
      genAIRequestId,
      userRating,
      userComments: ''
    };
    this.builderService
      .generateAudienceDefinitionGenAIQueryRating(generateFromTextRequest)
      .subscribe(() => {
        const updateFeedback = (rules, feedbackStatus) =>{
          rules.forEach(rule => {
            rule?.forEach(conditionGroup => {
              if (conditionGroup.genAIRequestId === genAIRequestId) {
                conditionGroup.feedbackStatus = feedbackStatus;
                if (feedbackStatus === 'timedout') conditionGroup.genAIRequestId = '';
              }
              else {
                conditionGroup.group?.forEach(condition => {
                  if (condition.genAIRequestId === genAIRequestId) {
                    condition.feedbackStatus = feedbackStatus;
                    if (feedbackStatus === 'timedout') condition.genAIRequestId = '';
                  }
                });
              }
            });
          });
        }
        updateFeedback(this.builderService.rules, 'submitted');
        setTimeout(() => updateFeedback(this.builderService.rules, 'timedout'), 5 * 1000)
      });
  }

  public cannotEnter(
    previousContainer,
    previousContainerIsPickerNode,
    attributeExpression,
    nestLevel,
    isEntrySegment,
    previousExpressionGroupIdx
  ): boolean {
    if (previousExpressionGroupIdx !== this.parentExpressionIdx) {
      return false;
    }
    const isEmptyingContainer =
      nestLevel <= this.nestLevel &&
      !reject(
        previousContainer,
        (item) =>
          item.group || item.displayName === attributeExpression.displayName
      ).length;
    return (
      !previousContainerIsPickerNode && isEmptyingContainer && !isEntrySegment
    );
  }

  public onDropped(
    event: any,
    groupIdx: number,
    isEntrySegment: boolean
  ): void {
    if (!this.isDropAllowed(event)) {
      this.enableDrop();
      return;
    }
    this.builderService.isElementManuallyDropped = true;
    if(event.container.element.nativeElement.classList.contains('drop-list-aggregation'))
    {
      //this.getAttributesInGroup(this.ruleGroup[groupIdx][elementIdx].group).isAggregatorDropped =true;
      this.aggregatorDropBox = false;
      this.enableAddAgrregationBoxOnDelete = false;
      this.isAggregatorDropped = true;
    }
    const typeName = groupIdx ? 'Exclude' : 'Include';
    if (!(event.previousContainer.data instanceof Array)) {
      const previousContainerData = event.previousContainer.data;
      event.previousContainer.data = [];
      event.previousContainer.data.push(previousContainerData);
    }
    const draggedItem = event.previousContainer.data[event.previousIndex];
    if((draggedItem as any)?.entityEnumId) {
      const entityEnumId = (draggedItem as any)?.entityEnumId;
      this.audienceService.getEnumValues(this.route.snapshot.paramMap.get('contextId'), entityEnumId).subscribe(res => {
       this.attributeEnumValues[(draggedItem as any).cabId] = res.values;
      });
    } else {
      this.attributeEnumValues[(draggedItem as any).cabId] = [];
    }
    const dropListClasses = get(
      event,
      'previousContainer.element.nativeElement.className',
      ''
    );

    const previousContainerIsPickerNode =
      dropListClasses?.includes('pcbc-nav-tree-node');
    const nestLevelCls = find(dropListClasses.split(' '), (str) =>
      str?.includes('nest-level-')
    );
    const nestLevel = nestLevelCls
      ? parseInt(
          last(
            find(nestLevelCls.split(' '), (str) =>
              str?.includes('nest-level-')
            ).split('-')
          ),
          10
        )
      : null;
    const previousExpressionGroupIdxCls = find(dropListClasses.split(' '), (str) =>
      str?.includes('expression-group-idx-')
    );
    const previousExpressionGroupIdx = previousExpressionGroupIdxCls
      ? parseInt(
          last(
            find(previousExpressionGroupIdxCls.split(' '), (str) =>
              str?.includes('expression-group-idx-')
            ).split('-')
          ),
          10
        )
      : null;
    const attributeExpression = this.convertAttributeToExpression(draggedItem, event.container.element.nativeElement.classList.contains('drop-list-aggregation'));
    if (event.previousContainer === event.container) {
      event.container.moveDragItem(event.previousIndex, event.currentIndex);
    } else if (
      this.cannotEnter(
        event.previousContainer.data,
        previousContainerIsPickerNode,
        attributeExpression,
        nestLevel,
        isEntrySegment,
        previousExpressionGroupIdx
      )
    ) {
      return;
    } else {
      if (!previousContainerIsPickerNode) {
        event.previousContainer.transferDragItemToNewList(
          event.container,
          event.previousIndex,
          event.currentIndex
        );
      } else {
        (event.container.data as any).splice(
          event.currentIndex,
          0,
          attributeExpression
        );
      }
      if (
        !this.findGroupItem(event.container.data) &&
        !this.findGroupItem(event.previousContainer.data) &&
        !previousContainerIsPickerNode
      ) {
        (event.previousContainer.data as any).splice(
          event.previousContainer.data.length,
          0,
          emptyAudienceRuleGroup(typeName)
        );
        (event.container.data as any).splice(
          event.currentIndex + 1,
          0,
          emptyAudienceRuleGroup(typeName)
        );
        const group = this.findGroupItem(event.previousContainer.data).group;
        forEach(event.container.data, (item) => {
          group.splice(event.previousContainer.data.length, 0, item);
        });
      } else if (!this.findGroupItem(event.container.data)) {
        (event.container.data as any).splice(
          event.currentIndex + 1,
          0,
          emptyAudienceRuleGroup(typeName)
        );
      } else if (
        (event.previousContainer.data as any).length === 1 &&
        !previousContainerIsPickerNode
      ) {
        // if group only has empty group left, remove
        event.previousContainer.data.splice(
          0,
          event.previousContainer.data.length
        );
      }

      if (
        last(this.ruleGroup[groupIdx]) &&
        last(this.ruleGroup[groupIdx]).group.length
      ) {
        this.ruleGroup[groupIdx].splice(
          this.ruleGroup[groupIdx].length,
          0,
          emptyAudienceRuleGroup(typeName)
        );
        event.previousContainer.data.splice(
          event.previousContainer.data.length,
          0
        );
      }
      this.clearEmptyGroups(this.ruleGroup);
      this.clearEmptyGroups(event.previousContainer.data);
      const hasExpression = hasExpressionGroup(event.previousContainer.data);
      if (!hasExpression && !hasAggregationGroup &&!previousContainerIsPickerNode) {
        event.previousContainer.data.splice(0, 1);
      }

      this.countsService.stopCron();
      this.countsService.resetBuilderCounts();
      this.builderService.definitionHasAttribute$.next(true);
      this.builderService.audienceBuilderUpdatedManually$.next(true);
      this.builderService.audienceBuilderCriteriaManually$.next(true);

    }
  }

  checkForAttribute() {
    this.builderService.checkForAttribute();
  }

  isDropAllowed(event: any) {
    if (!(event.item?.dropContainer?.data instanceof Array)) {
      const previousContainerData = event.item.dropContainer.data;
      event.item.dropContainer.data = [];
      event.item.dropContainer.data.push(previousContainerData);
    }

    const index = event.previousIndex || 0;
    const draggedAttribute = get(event, `item.dropContainer.data[${index}]`);
    const onEditAttributeCabId = get(
      draggedAttribute,
      'expression.operand.cabId'
    );
    const onEditAttributeDetails = get(
      getStoreState(this.store),
      `cab.audienceBuilder.attributeDetails.${onEditAttributeCabId}`
    );
    // prettier-ignore
    const dataType = (
      // dragged from picker
      draggedAttribute?.dataType ||
      // dragged from picker to another group on builder
      get(draggedAttribute, 'expression.operand.dataType') ||
      // on edit, existing attribute dragged to another group
      get(onEditAttributeDetails, ['dataType']) ||
      ''
    ).toLowerCase();
    const isCabAttribute = [
      DataType.AUDIENCE_DEFINITION,
      DataType.AUDIENCE_LIST,
    ]?.includes(dataType);

    if (!isCabAttribute) {
      return true;
    }

    const isDefinition = dataType === DataType.AUDIENCE_DEFINITION;
    let isDifferentDefinition = true;

    if (isDefinition) {
      isDifferentDefinition =
        draggedAttribute.cabId !== `cab:${this.builderService.audience?.id}`;
      if (this.builderService.audience?.isUsed) {
        this.definitionIsNested$.next(true);
        this.dropDisabled$.next(true);
      }
    }

    this.dropDisabled$.next(!isDifferentDefinition);
    return isDifferentDefinition;
  }

  enableDrop() {
    this.dropDisabled$.next(false);
    if (this.definitionIsNested$.value) {
      this.definitionIsNested$.next(false);
    }
  }

  clearDeletedAttributes(groupData, groupIdx, index, item) {
    //this.clearEmptyGroups(groupData);
    if (groupData[groupIdx][index]?.group?.length === 0) {
      groupData[groupIdx].splice(index, 1);
    }
    groupData[groupIdx].forEach((groupItem) => {
      const hasExpression = hasExpressionGroup(groupItem.group);
      const hasAggregateConditions = groupItem.group.some((item) => item.aggregationConditions?.length > 0);
      if (groupItem.group.length >= 1) {
        if (!hasExpression && !hasAggregateConditions) {
          groupData[groupIdx].splice(index, 1);
        }
      }
    });
    if(this.dragDropService.addAggregationButtonClick && groupData[0].length > 1 && !(!!item.expression.operand))
    {
      this.enableAddAgrregationBoxOnDelete = true;
    }
    this.checkForAttribute();
  }

  clearEmptyGroups(groupData) {
    forEach(groupData, (container) => {
      const emptyGroupItems = _filter(container, (g) => {
        if (g && g.group && g.group[0]?.group?.length === 0 && !(g.group[0]?.aggregationConditions?.length>=1) ) {
          container.splice(container.indexOf(g), 1);
        }
        return g && g.group && !g.group.length;
      });
      if (emptyGroupItems.length > 1) {
        container.splice(findIndex(last(emptyGroupItems)), 1);
      }
    });
  }

  getAttributesInGroup(conditionGroup) {
    return conditionGroup?.filter((group) => {
        return group.aggregationConditions?.length > 0 ? group : group.expression;
    });
  }

  findGroupItem(group) {
    return find(group, (item) => item.group && item.aggregationConditions?.length === 0);
  }

  hideExtraGroup(item, groupItems): boolean {
    return item.group && !groupItems.find((i) => i.group && i.group.length > 0);
  }

  updateGroupLogicalOperator(operator, group) {
    this.builderService.audienceBuilderUpdatedManually$.next(true);
    this.builderService.audienceBuilderCriteriaManually$.next(true);
    group.logicalOperator = operator;
  }

  nextGroup(group, index, getAttributesInGroup = false) {
    return getAttributesInGroup
      ? this.getAttributesInGroup(group)[index + 1]
      : group[index + 1];
  }

  isBooleanSelected(
    group: AudienceRuleGroup,
    selectedBoolean: AudienceBooleanType
  ) {
    return upperCase(group.logicalOperator) === upperCase(selectedBoolean);
  }

  convertAttributeToExpression(
    attribute: BuilderAttribute,
    isAggregator: boolean
  ): AudienceExpressionGroup {
    let expression;
    const dataType = attribute?.dataType?.toLowerCase();
    if (
      dataType === DataType.AUDIENCE_LIST ||
      dataType === DataType.AUDIENCE_DEFINITION
    ) {
      const isList = dataType === DataType.AUDIENCE_LIST;
      expression = {
        expressionType: 'SingleOperand',
        operand: {
          valueType: attribute.dataType,
          dataType: attribute.dataType,
          cabId: attribute.cabId,
          audienceListId: isList ? attribute.id : null,
          audienceDefinitionId: isList ? null : attribute.id,
          dedupeIdentityType: attribute.data?.validations?.validateDedupeType,
          path: attribute.path
        },
        comparisonOperator: '',
      };
    } else {
      expression = {
        expressionType: 'BasicComparison',
        firstOperand: {
          id: attribute.id,
          valueType: 'Attribute',
          name: attribute.displayName,
          dataSetId: attribute.rootId,
          cabId: attribute.cabId,
          path: attribute.path
        },
        comparisonOperator: '',
        secondOperand: {
          dataType: attribute.dataType as BuilderAudienceDataType,
          nativeDataType: attribute.nativeDataType,
          values: [],
          valueType: 'Literal'
        },
      };
    }
    return {
      displayName: attribute.displayName,
      logicalOperator: 'AND',
      isAggregator,
      expression
    };
  }

  toggleDrag() {
    this.isDragging$.next(!this.isDragging$.getValue());
  }

  getAggregateAttributesInGroup(group) {
    return group?.filter((group) => group.aggregationConditions);
  }

  getOperatorLabel(operator: AudienceBooleanType): string {
    if(operator === 'NOT') {
      return 'AND NOT';
    }
    return operator;
  }

  showGenerateAIPrompt(type: string) {
    this.generateAIPrompt.showModal(type, this.dedupeType.value);
  }
}
