import { NotificationComponent } from '@epsilon/core-ui';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { find, get, map as _map } from 'lodash-es';
import dayjs from 'dayjs';
import { DataType } from '../enums/data-types';
import { User } from '../user/user.models';

// Does the same thing as BehaviorSubject.getValue()
// Believe it or not this runs synchronously and returns the current state of the store
export function getStoreState<T>(store: Store<T>): T {
  let state: T;
  store.pipe(take(1)).subscribe((x) => (state = x));
  return state;
}

export const isDefined = <T>(v: T): v is Exclude<T, undefined> => !!v;

export const OKTA_TOKEN = 'cab-okta-token';
export const ACCESS_TOKEN = 'accessToken';
export const DEFAULT_ROUTE = '/';
export const REDIRECT_PARAM_NAME = 'cabLoginRedirectUri';
export const BASE_URL_PARAM_NAME = 'cabBaseLoginRedirectUrl';
export const PRODUCT_BASE_URL_PARAM_NAME = 'productBaseUrl';
export const CAB_API_URL_PARAM_NAME = 'cabApiUrl';
export const AMS_PROXY_URL_PARAM_NAME = 'apiUrl';
export const DATAHUB_URL_PARAM_NAME = 'dataHubApiUrl';
export const FOUNDATION_URL_PARAM_NAME = 'foundationApiUrl';

export const completeStatuses = ['COMPLETED', 'Complete', 'SUCCESS'];
export const UI_STATUS_COMPLETE = 'Completed';
export const UI_STATUS_ERROR = 'Failed';
export const UI_STATUS_PROCESSING = 'Processing';

export const BE_STATUS_SUCCESS = 'SUCCESS';
export const BE_STATUS_COMPLETE = 'COMPLETED';
export const BE_STATUS_ERROR = 'FAILURE';

export const BE_STATUS_PROCESSING = 'INPROGRESS';
export const BE_STATUS_MONITOR_PROCESSING = 'IN_PROGRESS';
export const BE_STATUS_NEW = 'NEW';

// ROUTES
export const AUDIENCE_TXT = 'audience';
export const DEFINITION_TXT = 'definition';
export const ACTIVITY_TXT= 'deliveries';
export const BUILDER_TXT = 'builder';
export const MONITOR_TXT = 'monitor';
export const MONITOR_HISTORY_TXT = 'history';
export const MONITOR_SCHEDULED_TXT = 'scheduled';
export const AUDIENCE_AI_SELECTOR_TXT = 'ai-selector';
export const AUDIENCE_AI_DAILOG_TXT = 'ai-dailog';

export function isDateNumeric(operator) {
  const dateNumericOperators = ['LAST', 'NEXT'];
  return dateNumericOperators.includes(operator);
}

export function isRelativeDate(expression) {
  const isDate = isDateType(expressionDataType(expression));
  const isRelativeDate =
    hasRelativeDateValue(expression, 'secondOperand.relativeDate') ||
    hasRelativeDateValue(expression, 'secondOperand.values');

  return isDate && isRelativeDate;
}

function hasRelativeDateValue(expression, collection) {
  return find(get(expression, collection), (v) =>
    String(v).includes('RELATIVE:')
  );
}

export function displayRelativeDateUnits(unit) {
  return `${unit.slice(0, -1)}(s)`;
}

export function formatDate(date, delimiter: '/' | '-') {
  return dayjs(date).format(`YYYY${delimiter}MM${delimiter}DD`);
}

export function formatDateTime(date, delimiter: '/' | '-') {
  return dayjs(date).format(`YYYY${delimiter}MM${delimiter}DDTHH:mm:ss`);
}

export function expressionDataType(expression) {
  // TODO: Need to have better way to get dataTypeOperator here
  return (
    get(expression, 'secondOperand.dataType')?.toLowerCase() ||
    get(expression, 'operand.valueType')?.toLowerCase()
  );
}

export function isDateType(dataType) {
  return dataType?.toLowerCase() === DataType.DATE || dataType?.toLowerCase() === DataType.DATE_TIME;
}

export function isCalendarDateType(expression, comparisonOperator) {
  const dataType = expressionDataType(expression);
  return isDateType(dataType) && !isDateNumeric(comparisonOperator);
}

export function formatCalendarDates(expression, forBackEnd: boolean) {
  return _map(get(expression, 'secondOperand.values'), (date) => {
    if (hasValidTime(dayjs(date))) {
      return formatDateTime(date, forBackEnd ? '-' : '/')
    }
    return formatDate(date, forBackEnd ? '-' : '/')
  });
}

export function hasValidTime(date) {
  const dateTime = new Date(date);
  if (dateTime && dateTime.getHours() === 0 && dateTime.getMinutes() === 0 && dateTime.getSeconds() === 0) {
    return false;
  }
  return true;
}

export function showToastNotification(item: NotificationComponent): void {
  item.show();
}

export function httpAbortLoadIndicator(params) {
  return { ...params, abortLoad: true };
}

export function hasAdminAccess(userDetails: User) {
  if(userDetails && userDetails.permissions) {
    return ['CREATE', 'READ', 'UPDATE', 'DELETE'].every(permission => userDetails.permissions.includes(permission));
  }
  return false;
}

export const isProfileDedupeIdentityType = (dedupeType: string) => {
  const profileDedupeIdentityTypes = ['Profile', 'CsCoreId', 'Email', 'AlternateKey'];
  return profileDedupeIdentityTypes.includes(dedupeType);
}