import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  capitalize,
  compact,
  forEach,
  get,
  isEmpty,
  isFinite,
} from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';
import { MenuComponent } from '@epsilon/core-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RELATIVE_VALUE } from '../../../audience-builder/audience-builder.models';
import { UntypedFormGroup } from '@angular/forms';
import { displayRelativeDateUnits } from '../../../utils/utils';

@UntilDestroy()
@Component({
  selector: 'lib-relative-date-menu',
  templateUrl: './relative-date-menu.component.html',
  styleUrls: ['./relative-date-menu.component.sass'],
})
export class RelativeDateMenuComponent implements OnInit {
  @Input() attributeForm: UntypedFormGroup;
  @Input() attribute;
  @Input() label$: BehaviorSubject<string>;
  @Input() relativeDate$: BehaviorSubject<string>;
  @Input() activeOperatorTab$: BehaviorSubject<string>;
  @Input() isMulti = false;
  relativeValueTimeOptions = ['Days', 'Months', 'Years'];
  relativeValueOptions = ['ago', 'from now'];
  today$ = new BehaviorSubject(dayjs().format('MM/DD/YYYY'));
  @ViewChild('relativeDateMenu') private relativeDateMenu: MenuComponent;
  displayRelativeDateUnits = displayRelativeDateUnits;

  constructor() {
    // RelativeDateMenuComponent constructor
  }

  ngOnInit(): void {
    forEach(
      ['relativeDateNum', 'relativeDateUnits', 'relativeDateOperator'],
      (formField) => {
        this.attributeForm
          .get(this.setFormFieldName(formField))
          .valueChanges.pipe(untilDestroyed(this))
          .subscribe(() => this.setRelativeDate());
      }
    );

    this.handleDisabledRelativeDateInputs();
    this.setRelativeDateDefaults();
  }


  setRelativeDate() {
    const relativeDateUnits = this.relativeDateUnits()?.toLowerCase();
    let date;
    if (this.relativeDateRadioIs('custom')) {
      date =
        this.relativeDateOperator()?.toLowerCase() === 'Ago'.toLowerCase()
          ? dayjs(
              dayjs().subtract(this.relativeDateNumber(), relativeDateUnits)
            )
          : dayjs(dayjs().add(this.relativeDateNumber(), relativeDateUnits));
      date = dayjs(date.toDate()).format(`MM/DD/YYYY`);
      this.relativeDate$.next(date);
    } else {
      this.relativeDate$.next(this.today$.value);
    }
  }

  saveRelativeDate() {
    const relativeDate = get(
      this.attribute,
      'expression.secondOperand.relativeDate',
      []
    );
    if (this.relativeDateRadioIs('today')) {
      const today = 'RELATIVE:0:DAYS';
      this.label$.next('Today');
      this.attribute.expression.secondOperand.relativeDate = this.isMulti
        ? [relativeDate[0], today]
        : compact([today, relativeDate[1]]);
    } else {
      this.label$.next(
        isFinite(this.relativeDateNumber())
          ? `${this.relativeDateNumber()} ${displayRelativeDateUnits(
              this.relativeDateUnits()
            )} ${this.relativeDateOperator()}`
          : 'Set Value'
      );
      const expressionRelativeDate = `RELATIVE:${
        this.relativeDateOperator()?.toLowerCase() === 'Ago'?.toLowerCase() ? '-' : ''
      }${this.relativeDateNumber()}:${this.relativeDateUnits().toUpperCase()}`;
      this.attribute.expression.secondOperand.relativeDate = this.isMulti
        ? [relativeDate[0], expressionRelativeDate]
        : compact([expressionRelativeDate, relativeDate[1]]);
    }
    this.setRelativeDate();
  }

  closeRelativeDateMenu(action?: 'save') {
    if (action === 'save') {
      this.saveRelativeDate();
    } else {
      this.resetRadio();
    }
    this.relativeDateMenu.hideMenu();
  }

  setRelativeDateLabel() {
    const relativeDateNumber = parseInt(this.relativeDateNumber(), 10);
    this.label$.next(
      relativeDateNumber === 0
        ? 'Today'
        : isFinite(relativeDateNumber)
        ? `${relativeDateNumber} ${displayRelativeDateUnits(
            this.relativeDateUnits()
          )} ${this.relativeDateOperator()}`
        : 'Set Value'
    );
  }

  hasCustomRelativeDateValue() {
    return isFinite(this.relativeDateNumber());
  }

  relativeDateRadioValue() {
    return this.attributeForm.get(this.setFormFieldName('relativeDateRadio'))
      ?.value;
  }

  relativeDateRadioIs(radioValue: 'custom' | 'today'): boolean {
    return this.relativeDateRadioValue() === radioValue;
  }

  setRadio(val) {
    this.attributeForm
      .get(this.setFormFieldName('relativeDateRadio'))
      .setValue(val);
  }

  resetRadio() {
    const radioValue = this.label$.value === 'Today' ? 'today' : 'custom';
    this.attributeForm
      .get(this.setFormFieldName('relativeDateRadio'))
      .setValue(radioValue);
  }

  relativeDateDefaults(relativeDates, index = 0) {
    this.activeOperatorTab$.next(RELATIVE_VALUE);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, timeNum, relativeDateOperator] = relativeDates[index]?.split(':');
    this.attributeForm
      .get(this.setFormFieldName('relativeDateNum'))
      .setValue(parseInt(timeNum.replace('-', ''), 10));
    this.attributeForm
      .get(this.setFormFieldName('relativeDateUnits'))
      .setValue(capitalize(relativeDateOperator));
    this.attributeForm
      .get(this.setFormFieldName('relativeDateOperator'))
      .setValue(timeNum.includes('-') ? 'ago' : 'from now');
    this.attributeForm
      .get(this.setFormFieldName('relativeDateRadio'))
      .setValue(timeNum === '0' ? 'today' : 'custom');
    this.setRelativeDate();
    this.setRelativeDateLabel();
  }

  setRelativeDateDefaults() {
    const relativeDates = get(
      this.attribute,
      'expression.secondOperand.relativeDate'
    );
    if (!isEmpty(relativeDates)) {
      if (this.isMulti) {
        this.relativeDateDefaults(relativeDates, 1);
      } else {
        this.relativeDateDefaults(relativeDates);
      }
    }
  }

  handleDisabledRelativeDateInputs() {
    this.attributeForm
      .get(this.setFormFieldName('relativeDateRadio'))
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        forEach(
          [
            this.setFormFieldName('relativeDateNum'),
            this.setFormFieldName('relativeDateUnits'),
            this.setFormFieldName('relativeDateOperator'),
          ],
          (formField) => {
            if (val === 'today') {
              this.attributeForm.get(formField).disable();
            } else {
              this.attributeForm.get(formField).enable();
            }
          }
        );
      });
  }

  setFormFieldName(fieldName) {
    return `${fieldName}${this.isMulti ? 'Multi' : ''}`;
  }

  relativeDateNumber() {
    return this.attributeForm.get(this.setFormFieldName('relativeDateNum'))
      .value;
  }

  relativeDateUnits() {
    return this.attributeForm.get(this.setFormFieldName('relativeDateUnits'))
      .value;
  }

  relativeDateOperator() {
    return this.attributeForm.get(this.setFormFieldName('relativeDateOperator'))
      .value;
  }

  getRelativeDateUnitsFormLabel() {
    const value = this.relativeDateUnits();
    if (value) {
      const title = displayRelativeDateUnits(value);
      if (title.length) {
        return `${title[0].toUpperCase() + title.substring(1).toLowerCase()}`;
      }
    }
    return 'Select';
  }

  updateRelativeDateUnitsFormValue(value) {
    this.attributeForm
      .get(this.setFormFieldName('relativeDateUnits'))
      .setValue(value);
    this.setRelativeDate()
  }

  getRelativeDateOperatorFormLabel() {
    const value = this.relativeDateOperator();
    if (value) {
      if (value.length) {
        return `${value[0].toUpperCase() + value.substring(1).toLowerCase()}`;
      }
    }
    return 'Select';
  }

  updateRelativeDateOperatorFormValue(value) {
    this.attributeForm
      .get(this.setFormFieldName('relativeDateOperator'))
      .setValue(value);
    this.setRelativeDate()
  }
}
