import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../reducers';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TableFilterService } from '../table-filter.service';
import { Store } from '@ngrx/store';
import {
  DestroyFeedDataList,
  FeedDataList,
} from '../../../feed-data-list/feed-data-list.actions';
import { UntypedFormGroup } from '@angular/forms';
import { Asset } from '../../../feed-data-list/feed-data-list.model';
import {
  combineLatest as observableCombineLatest,
  Observable,
  map,
  tap,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sortBy, values } from 'lodash-es';
import {
  selectFeedDataList,
  selectFeedDataListInitialState,
  selectHasMore,
} from '../../../feed-data-list/feed-data-list.reducer';
import { selectActiveDataUniverse } from '../../../data-universe/data-universe.reducer';
import { selectTenantContext } from '../../../tenant/tenant.reducer';
import { LoadingService } from '../../../services/loading.service';
import { DatePipe } from '@angular/common';
import { UtilsService } from '../../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-feed-data-table',
  templateUrl: './feed-data-table.component.html',
  styleUrls: ['./feed-data-table.component.sass'],
})
export class FeedDataTableComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  filteredFeedDataList: Asset[] = [];
  filteredFeedDataList$: Observable<Asset[]>;
  hasMore = true;
  contextId: string;
  dataUniverseId: string;
  searchPayload: {
    limit?: number;
  } = {
    limit: 25,
  };
  feedDataList$ = this.store.select(selectFeedDataList).pipe(
    map(values),
    map((audiences) => {
      return audiences;
    })
  );
  feedDataListHasMore$ = this.store.select(selectHasMore).subscribe((value) => {
    this.hasMore = value;
  });

  isLoader = false;
  initialLoad = true;
  businessUnitId: string;

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private tableFilterService: TableFilterService,
    protected loadingService: LoadingService,
    private datePipe: DatePipe,
    private utilsService: UtilsService
  ) {
    this.filteredFeedDataList = null;
    this.formGroup = tableFilterService.formGroup;
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');

    this.businessUnitId = this.getBusinessUnitId();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const businessUnitId = this.getBusinessUnitId();
        if (businessUnitId !== this.businessUnitId) {
          this.businessUnitId = businessUnitId;
          this.resetSearchPayload();
          this.loadTableDataAndSetIsLoader();
        }
      }
    });
  }

  getBusinessUnitId() {
    const businessUnitId = this.utilsService.getBusinessUnitId(this.route);
    return businessUnitId != 'DEFAULT' ? businessUnitId : null;
  }

  ngOnInit(): void {
    this.filteredFeedDataList = null;
    observableCombineLatest([
      this.store.select(selectTenantContext),
      this.store.select(selectActiveDataUniverse),
    ]).subscribe(([contextId, activeDataUniverse]) => {
      if (contextId && contextId !== this.contextId) {
        this.contextId = contextId;
      }
      if (activeDataUniverse) {
        this.dataUniverseId = activeDataUniverse?.id;
      }
      this.resetSearchPayload();
      this.loadTableDataAndSetIsLoader();
    });

    this.filteredFeedDataList$ = observableCombineLatest([
      this.feedDataList$,
    ]).pipe(
      untilDestroyed(this),
      map(([audiences]: [Asset[]]) => {
        return audiences;
      })
    );

    this.isLoader = true;
    this.store
      .select(selectFeedDataListInitialState)
      .pipe(untilDestroyed(this))
      .subscribe((initialLoad: boolean) => {
        this.initialLoad = initialLoad;
      });

    this.filteredFeedDataList$
      .pipe(
        tap(this.tableFilterService.resetFormRows.bind(this)),
        untilDestroyed(this)
      )
      .subscribe((audiences) => {
        this.filteredFeedDataList = [];
        // eslint-disable-next-line guard-for-in
        for (const audiencesList in audiences) {
          this.filteredFeedDataList?.push(audiences[audiencesList]);
        }
        if (this.filteredFeedDataList.length) {
          this.filteredFeedDataList = sortBy(this.filteredFeedDataList, [
            function (o) {
              return o['data']['lastRunTime'];
            }
          ]).reverse();
          this.isLoader = false;
        }
      });
  }

  public search() {
    const searchDHPayload = {
      assetSystem: 'DATAHUB',
      assetType: 'FEED',
      limit: this.searchPayload.limit,
      businessUnitId: this.businessUnitId,
    };
    const contextId = this.contextId?.split('-')?.[1];
    this.store.dispatch(new FeedDataList(contextId, searchDHPayload));
  }

  resetSearchPayload() {
    this.searchPayload.limit = 25;
    this.filteredFeedDataList = [];
  }

  public loadTableDataAndSetIsLoader() {
    this.isLoader = true;

    this.search();
    if (this.filteredFeedDataList.length) {
      this.isLoader = false;
    }
  }

  navigateTosendTo(item): void {
    const audienceEntityType =
      item.tags['CAB-Audience-Type'] === 'list' ? 'list' : 'definition';
    this.router.navigate(
      [
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        this.dataUniverseId,
        item.tags['CAB-Audience-Id'],
        'sendTo',
        item.id,
      ],
      { queryParams: { type: audienceEntityType } }
    );
  }

  public limitReached() {
    if (this.hasMore) {
      this.searchPayload.limit += 25;
      this.search();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new DestroyFeedDataList());
  }
}
